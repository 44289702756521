<template>
  <div v-if="type === 'textarea'" class="form-group">
    <label v-if="label" :for="ID" class="form-label">{{ label }} </label>
    <textarea
      :id="ID"
      v-bind="$attrs"
      class="form-control form-control-lg"
      :class="classes"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    ></textarea>
    <div v-if="hint" class="form-text">{{ hint }}</div>
  </div>
  <div v-else class="form-group">
    <label v-if="label" :for="ID" class="form-label">{{ label }} </label>
    <input
      :id="ID"
      :type="type"
      v-bind="$attrs"
      class="form-control form-control-lg"
      :class="classes"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <div v-if="hint" class="form-text">{{ hint }}</div>
  </div>
</template>
<script>
import { v4 as uuidv4 } from "uuid";
export default {
  props: {
    id: {
      type: String,
      // default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      default: "",
    },
    hint: {
      type: String,
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    classes: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const ID = props.id ? props.id : uuidv4();
    return { ID };
  },
};
</script>