import { Util } from '@/helpers/utilities.js'
import axios from 'axios'
import { CustomerState as State } from '@/store/store-helper.js'

export default {
  namespaced: true,
  state: {
    initialized: false,
    completed: {},
    customer: {},
    customers: []
  },
  mutations: {
    logOut: (state) => {
      for (const property in state) {
        if (property in State) {
          state[property] = State[property]
        } else {
          delete state[property]
        }
      }
    }
  },
  actions: {
    getCustomers({ state, commit, rootState }) {
      if (state.customers.length > 0) return
      commit('showPreloader', 'fetching customers...', { root: true })
      axios
        .get('/api/customers/' + rootState.auth.userMerchant.uuid)
        .then((response) => {
          if (response.data.status === 'success') {
            state.customers = response.data.result.customers
          } else {
            Util.CatchError(response)
          }
        })
        .catch((error) => {
          if (error.response.status === 401)
            commit('auth/logOutUser', { root: true })
          else Util.Error(error.message, 'Error: ' + error.code)
        })
        .finally(() => {
          commit('hidePreloader', null, { root: true })
        })
    },
    async getCustomer({ state, commit, dispatch, rootState }, uuid) {
      if (!uuid) return
      commit('showPreloader', 'Fetching customer...', { root: true })
      rootState.fetching.getCustomer = true
      return axios
        .get('/api/customer/' + uuid)
        .then((response) => {
          if (response.data.status === 'success') {
            state.customer = response.data.result
            return response.data.result
          } else {
            Util.Error(response.data.result)
          }
        })
        .catch((error) => {
          if (error.response?.status === 401)
            commit('auth/logOutUser', { root: true })
          else Util.Error(error.message, 'Error: ' + error.code)
        })
        .finally(() => {
          commit('hidePreloader', null, { root: true })
          dispatch('stopFetching', 'getCustomer', { root: true })
        })
    },
    async updateCustomer({ commit, state, rootState }, customer) {
      customer.merchant_uuid = rootState.auth.userMerchant.uuid
      commit('showPreloader', 'Saving customer...', { root: true })
      state.completed.updateCustomer = false
      if (customer.uuid) {
        return axios
          .put('/api/customers/update', customer)
          .then((response) => {
            if (response.data.status === 'success') {
              state.customer = response.data.result.customer
              state.customers = response.data.result.customers

              Util.notify({
                message: 'Customer updated successfully',
                duration: 5,
                status: 'success'
              })
              state.completed.updateCustomer = true
              return response.data
            } else {
              Util.Error(response.data.result)
            }
          })
          .catch((error) => {
            if (error.response.status === 401)
              commit('auth/logOutUser', { root: true })
            else Util.Error(error.message, 'Error: ' + error.code)
          })
          .finally(() => {
            commit('hidePreloader', null, { root: true })
          })
      } else {
        return axios
          .post('/api/customers/register', customer)
          .then((response) => {
            if (response.data.status === 'success') {
              state.customer = response.data.result.customer
              state.customers = response.data.result.customers

              Util.notify({
                message: 'Customer registered successfully',
                duration: 5,
                status: 'success'
              })
              state.completed.updateCustomer = true
              return response.data
            } else {
              Util.Error(response.data.result)
            }
          })
          .catch((error) => {
            if (error.response.status === 401)
              commit('auth/logOutUser', { root: true })
            else Util.Error(error.message, 'Error: ' + error.code)
          })
          .finally(() => {
            commit('hidePreloader', null, { root: true })
          })
      }
    },
    setCustomer({ state }, customer) {
      state.customer = customer
    },
    unsetCustomer({ state }) {
      state.customer = {}
    },
    setCustomers({ state }, customers) {
      state.customers = customers
    }
  },
  getters: {
    customer: (state) => state?.customer ?? {},
    customers: (state) => state?.customers ?? [],
    initialized: (state) => state?.initialized,
    completed: (state) => state?.completed
  }
}
