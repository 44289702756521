import { appName, Util } from '@/helpers/utilities.js'
import { MerchantState as State } from '@/store/store-helper.js'
import axios from 'axios'
import router from '../router'

export default {
  namespaced: true,
  state: {
    initialized: false,
    merchant: {},
    merchants: []
  },
  mutations: {
    logOut: (state) => {
      for (const property in state) {
        if (property in State) {
          state[property] = State[property]
        } else {
          delete state[property]
        }
      }
    }
  },
  actions: {
    init({ commit, dispatch, state, rootState }, payload) {
      commit('showIndicator', null, {
        root: true
      })
      return axios
        .get(
          '/api/merchants/init/' +
            rootState.auth.userData.uuid +
            '/' +
            payload.muuid
        )
        .then(async (response) => {
          const data = response.data
          if (data.status === 'success') {
            const merchant = data.result.merchant
            const customers = data.result.customers
            const vendors = data.result.vendors
            const manufacturers = data.result.manufacturers
            const warehouses = data.result.warehouses
            const items = data.result.items
            const taxes = data.result.taxes
            const menu = data.result.menu
            dispatch('auth/setUserMerchant', merchant, { root: true })
            dispatch('auth/setUserMenu', menu, { root: true })
            dispatch('customers/setCustomers', customers, { root: true })
            dispatch('vendors/setVendors', vendors, { root: true })
            dispatch('manufacturers/setManufacturers', manufacturers, {
              root: true
            })
            dispatch('warehouses/setWarehouses', warehouses, { root: true })
            dispatch('items/setItems', items, { root: true })
            dispatch('taxes/setTaxes', taxes, { root: true })
            state.initialized = true
            const pathName = localStorage.getItem(appName + '-lp')
            const redirect =
              !pathName || pathName === 'login' ? 'dashboard' : pathName
            router.push({ name: redirect })
          } else {
            if (response?.status === 401)
              commit('auth/logOutUser', { root: true })
            Util.Error(response.data.result)
          }
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            commit('auth/logOutUser', { root: true })
          } else {
            Util.Error(error.message)
          }
        })
        .finally(() => {
          rootState.auth.initialized = true
          commit('hideIndicator', null, { root: true })
        })
    }
  },
  getters: {
    merchant: (state) => state?.merchant ?? {},
    merchants: (state) => state?.merchants ?? [],
    initialized: (state) => state?.initialized
  }
}
