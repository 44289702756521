import { Util } from '@/helpers/utilities.js'
import { BusinessState as State } from '@/store/store-helper.js'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    initialized: false,
    business: {},
    businesses: []
  },
  mutations: {
    logOut: (state) => {
      for (const property in state) {
        if (property in State) {
          state[property] = State[property]
        } else {
          delete state[property]
        }
      }
    }
  },
  actions: {
    async init({ commit, state, rootState }) {
      if (state.initialized) return
      commit('showPreloader', 'Initializing business...', { root: true })
      axios
        .get('/api/businesses/init/' + rootState.auth.userData.uuid)
        .then((response) => {
          commit('hidePreloader', null, { root: true })
          if (response.data.status === 'success') {
            const config = response.data.result
            console.log('business', config)
            if (config) {
              for (const cfg in config) {
                state[cfg] = config[cfg]
              }
            }
            state.initialized = true
          } else {
            Util.Error(response.data.result)
          }
        })
        .catch((error) => {
          if (error.response.status === 401)
            commit('auth/logOutUser', { root: true })
          else Util.Error(error.message, 'Error: ' + error.code)
        })
        .finally(() => {
          commit('hidePreloader', null, { root: true })
        })
    },
    GetBusinesses({ state, commit, rootState }) {
      if (state.businesses.length > 0) return
      commit('showPreloader', 'Fetching businesses...', { root: true })
      axios
        .get('/api/businesses', { business_uuid: rootState.auth.business_uuid })
        .then((response) => {
          if (response.data.status === 'success') {
            state.businesses = response.data.result.businesses
          } else {
            Util.CatchError(response)
          }
        })
        .catch((error) => {
          if (error.response.status === 401)
            commit('auth/logOutUser', { root: true })
          else Util.Error(error.message, 'Error: ' + error.code)
        })
        .finally(() => {
          commit('hidePreloader', null, { root: true })
        })
    },
    UpdateBusiness({ commit, state, rootState }, business) {
      commit('showPreloader', 'Saving business...', { root: true })
      business.user_uuid = rootState.auth.userData.uuid
      if (business.uuid) {
        axios
          .put('/api/businesses/register', business)
          .then((response) => {
            if (response.data.status === 'success') {
              state.business = response.data.result.business
              state.businesses = response.data.result.businesses
            } else {
              Util.CatchError(response)
            }
          })
          .catch((error) => {
            if (error.response.status === 401)
              commit('auth/logOutUser', { root: true })
            else Util.Error(error.message, 'Error: ' + error.code)
          })
          .finally(() => {
            commit('hidePreloader', null, { root: true })
          })
      } else {
        axios
          .post('/api/businesses/update', business)
          .then((response) => {
            if (response.data.status === 'success') {
              state.business = response.data.result.business
              state.businesses = response.data.result.businesses
            } else {
              Util.CatchError(response)
            }
          })
          .catch((error) => {
            if (error.response.status === 401)
              commit('auth/logOutUser', { root: true })
            else Util.Error(error.message, 'Error: ' + error.code)
          })
          .finally(() => {
            commit('hidePreloader', null, { root: true })
          })
      }
    }
  },
  getters: {
    business: (state) => state.business ?? {},
    businesses: (state) => state.businesses ?? [],
    initialized: (state) => state.initialized
  }
}
