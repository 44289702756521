<template>
  <teleport v-if="isLoggedIn" to="#AppSidebar">
    <AppSidebar />
  </teleport>
  <teleport v-if="isLoggedIn" to="#AppHeader">
    <AppHeader />
  </teleport>
  <teleport v-if="authenticated" to="#AppSideBarMenu">
    <AppSideBarMenu />
  </teleport>
  <teleport v-if="authenticated" to="#Breadcrumb">
    <h3 v-if="Breadcrumb" class="size20 m-0 font-bold truncate w3-text-theme">
      {{ Breadcrumb }}
    </h3>
    <div id="actionBar"></div>
  </teleport>
</template>
<script>
import AppHeader from "@/components/AppHeader.vue";
import AppSidebar from "@/components/AppSidebar.vue";
import AppSideBarMenu from "@/components/AppSideBarMenu.vue";
import { useStore } from "vuex";
import { computed, watchEffect } from "vue";
import $ from "jquery";
export default {
  name: "AppRoot",
  components: {
    AppHeader,
    AppSidebar,
    AppSideBarMenu,
  },
  setup() {
    const store = useStore();
    const Breadcrumb = computed(() => store.getters.breadcrumb);
    const appTitle = computed(() => store.state.appTitle);
    const appSubTitle = computed(() => store.state.appSubTitle);
    const appVersion = computed(() => store.state.appVersion);
    const isLoggedIn = computed(() => store.getters["auth/isLoggedIn"]);
    const isMerchantSet = computed(() => store.getters["auth/isMerchantSet"]);
    const authenticated = computed(
      () => isLoggedIn.value && isMerchantSet.value
    );
    const Menu = computed(() => store.getters["auth/userMenu"]);
    const SidebarState = computed(() => store.getters.sidebarState);
    watchEffect(() => {
      $("body").removeClass("expanded").removeClass("expanded");
      $("body").addClass(SidebarState.value);

      const AppHeader = document.querySelector("#AppHeader");
      const AppSidebar = document.querySelector("#AppSidebar");
      const AppSideBarMenu = document.querySelector("#AppSideBarMenu");
      if (isLoggedIn.value) {
        if (AppHeader.classList.contains("hideit"))
          AppHeader.classList.remove("hideit");
        if (AppSidebar.classList.contains("hideit"))
          AppSidebar.classList.remove("hideit");
      } else {
        if (!AppHeader.classList.contains("hideit"))
          AppHeader.classList.add("hideit");
        if (!AppSidebar.classList.contains("hideit"))
          AppSidebar.classList.add("hideit");
      }
      if (authenticated.value) {
        if (AppSideBarMenu.classList.contains("hideit"))
          AppSideBarMenu.classList.remove("hideit");
      } else {
        if (!AppSideBarMenu.classList.contains("hideit"))
          AppSideBarMenu.classList.add("hideit");
      }
    });
    return {
      Breadcrumb,
      appTitle,
      appSubTitle,
      appVersion,
      isLoggedIn,
      isMerchantSet,
      authenticated,
      Menu,
      SidebarState,
    };
  },
};
</script>
