import { Util } from '@/helpers/utilities.js'
import { ManufacturerState as State } from '@/store/store-helper.js'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    initialized: false,
    completed: {},
    manufacturer: {},
    manufacturers: []
  },
  mutations: {
    logOut: (state) => {
      for (const property in state) {
        if (property in State) {
          state[property] = State[property]
        } else {
          delete state[property]
        }
      }
    }
  },
  actions: {
    async getManufacturer({ state, commit, dispatch, rootState }, uuid) {
      if (!uuid) return
      commit('showPreloader', 'fetching manufacturer...', { root: true })
      rootState.fetching.getManufacturer = true
      return axios
        .get('/api/manufacturer/' + uuid)
        .then((response) => {
          if (response.data.status === 'success') {
            state.manufacturer = response.data.result.manufacturer
            return state.manufacturer
          } else {
            Util.Error(response.data.result)
          }
        })
        .catch((error) => {
          if (error.response?.status === 401)
            commit('auth/logOutUser', { root: true })
          else Util.Error(error.message, 'Error: ' + error.code)
        })
        .finally(() => {
          commit('hidePreloader', null, { root: true })
          dispatch('stopFetching', 'getManufacturer', { root: true })
        })
    },
    getManufacturers({ state, commit, rootState }) {
      if (state.manufacturers.length > 0) return
      commit('showPreloader', 'fetching manufacturers...', { root: true })
      axios
        .get('/api/manufacturers/' + rootState.auth.userMerchant.uuid)
        .then((response) => {
          if (response.data.status === 'success') {
            state.manufacturers = response.data.result.manufacturers
          } else {
            Util.CatchError(response)
          }
        })
        .catch((error) => {
          if (error.response.status === 401)
            commit('auth/logOutUser', { root: true })
          else Util.Error(error.message, 'Error: ' + error.code)
        })
        .finally(() => {
          commit('hidePreloader', null, { root: true })
        })
    },
    updateManufacturer({ commit, state, rootState }, manufacturer) {
      commit('showIndicator', 'Saving manufacturer...', { root: true })
      state.completed.UpdateManufacturer = false
      if (manufacturer.uuid) manufacturer._method = 'put'
      if (!manufacturer.uuid)
        manufacturer.merchant_uuid = rootState.auth.userMerchant.uuid
      return axios
        .post('/api/manufacturers/update', manufacturer)
        .then((response) => {
          if (response.data.status === 'success') {
            state.manufacturer = response.data.result.manufacturer
            state.manufacturers = response.data.result.manufacturers
            Util.notify({
              message: 'Manufacturer updated successfully',
              duration: 5,
              status: 'success'
            })
            state.completed.UpdateManufacturer = true
            return response.data
          } else {
            Util.Error(response.data.result)
            return null
          }
        })
        .catch((error) => {
          if (error.response.status === 401)
            commit('auth/logOutUser', { root: true })
          else Util.Error(error.message, 'Error: ' + error.code)
          return null
        })
        .finally(() => {
          commit('hideIndicator', null, { root: true })
        })
    },
    setManufacturer({ state }, manufacturer) {
      state.manufacturer = manufacturer
    },

    unsetManufacturer({ state }) {
      state.manufacturer = {}
    },
    setManufacturers({ state }, manufacturers) {
      state.manufacturers = manufacturers
    }
  },
  getters: {
    manufacturer: (state) => state?.manufacturer ?? {},
    manufacturers: (state) => state?.manufacturers ?? [],
    initialized: (state) => state?.initialized,
    completed: (state) => state?.completed
  }
}
