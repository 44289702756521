import { Util } from '@/helpers/utilities.js'
import { VendorState as State } from '@/store/store-helper.js'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    initialized: false,
    completed: {},
    vendor: {},
    vendors: []
  },
  mutations: {
    logOut: (state) => {
      for (const property in state) {
        if (property in State) {
          state[property] = State[property]
        } else {
          delete state[property]
        }
      }
    }
  },
  actions: {
    async getVendor({ state, commit, dispatch, rootState }, uuid) {
      if (!uuid) return
      commit('showPreloader', 'fetching vendor...', { root: true })
      rootState.fetching.getVendor = true
      return axios
        .get('/api/vendor/' + uuid)
        .then((response) => {
          if (response.data.status === 'success') {
            state.vendor = response.data.result.vendor
            return state.vendor
          } else {
            Util.Error(response.data.result)
          }
        })
        .catch((error) => {
          if (error.response?.status === 401)
            commit('auth/logOutUser', { root: true })
          else Util.Error(error.message, 'Error: ' + error.code)
        })
        .finally(() => {
          commit('hidePreloader', null, { root: true })
          dispatch('stopFetching', 'getVendor', { root: true })
        })
    },
    getVendors({ state, commit, rootState }) {
      if (state.vendors.length > 0) return
      commit('showPreloader', 'fetching vendors...', { root: true })
      axios
        .get('/api/vendors/' + rootState.auth.userMerchant.uuid)
        .then((response) => {
          if (response.data.status === 'success') {
            state.vendors = response.data.result.vendors
          } else {
            Util.CatchError(response)
          }
        })
        .catch((error) => {
          if (error.response.status === 401)
            commit('auth/logOutUser', { root: true })
          else Util.Error(error.message, 'Error: ' + error.code)
        })
        .finally(() => {
          commit('hidePreloader', null, { root: true })
        })
    },
    updateVendor({ commit, state, rootState }, vendor) {
      commit('showIndicator', 'Saving vendor...', { root: true })
      state.completed.UpdateVendor = false
      if (vendor.uuid) vendor._method = 'put'
      if (!vendor.uuid) vendor.merchant_uuid = rootState.auth.userMerchant.uuid
      return axios
        .post('/api/vendors/update', vendor)
        .then((response) => {
          if (response.data.status === 'success') {
            state.vendor = response.data.result.vendor
            state.vendors = response.data.result.vendors
            Util.notify({
              message: 'Vendor updated successfully',
              duration: 5,
              status: 'success'
            })
            state.completed.UpdateVendor = true
            return response.data
          } else {
            Util.Error(response.data.result)
            return null
          }
        })
        .catch((error) => {
          if (error.response.status === 401)
            commit('auth/logOutUser', { root: true })
          else Util.Error(error.message, 'Error: ' + error.code)
          return null
        })
        .finally(() => {
          commit('hideIndicator', null, { root: true })
        })
    },
    setVendor({ state }, vendor) {
      state.vendor = vendor
    },

    unsetVendor({ state }) {
      state.vendor = {}
    },
    setVendors({ state }, vendors) {
      state.vendors = vendors
    }
  },
  getters: {
    vendor: (state) => state?.vendor ?? {},
    vendors: (state) => state?.vendors ?? [],
    initialized: (state) => state?.initialized,
    completed: (state) => state?.completed
  }
}
