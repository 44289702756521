<template>
  <div class="custom-control custom-checkbox">
    <input
      type="checkbox"
      :checked="modelValue"
      v-bind="$attrs"
      @change="$emit('update:modelValue', $event.target.checked)"
      class="custom-control-input"
      :class="classes"
      :id="ID"
    />
    <label class="custom-control-label" :for="ID">{{ label }}</label>
  </div>
</template>
<script>
import { v4 as uuidv4 } from "uuid";
export default {
  props: {
    id: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const ID = props.id ? props.id : uuidv4();
    return { ID };
  },
};
</script>