<template>
  <Modal
    nonclosable
    width="400px"
    overlay="rgba(255, 255, 255, 0.50)"
    class="w3-theme-l1"
  >
    <div class="body-locked">
      <div class="w3-content px-3 pt-5 pb-4">
        <form>
          <h4 class="w3-text-white my-0 w3-center">Your session has expired</h4>
          <p class="size13 w3-text-white mb-3 w3-center">Unlock to continue</p>
          <div
            :class="[User.photo_url ? 'current-user' : '']"
            class="text-center"
          >
            <img
              :src="User.photo_url"
              :alt="User.fullname"
              style="width: 30%"
              class="rounded-circle"
            />
            <h4 class="w3-text-white">{{ User.fullname }}</h4>
            <h5 class="mb-2 w3-text-white size13">
              {{ User.email }}
            </h5>
          </div>

          <div
            class="
              form-group
              mb-1
              width250
              mx-auto
              w3-round-xxlarge
              overflow-hidden
            "
          >
            <div class="input-group">
              <input
                v-model="Password"
                name="Password"
                type="password"
                class="form-control form-control-lg w3-center"
                placeholder="Password"
              />
              <span class="input-group-append"
                ><span class="input-group-text"
                  ><i class="fas fa-lock"></i>
                </span>
              </span>
            </div>
          </div>
          <p class="mb-4 size13 w3-center w3-text-white">
            Not {{ User.fullname }}?
            <a @click="Logout" href="#" class="">Logout</a>
          </p>
          <div class="w3-center">
            <button
              @click.prevent="UnlockScreen"
              type="submit"
              class="
                w3-button
                w3-border
                w3-border-white
                w3-theme
                w3-hover-theme
                w3-round-xxlarge
                px-5
                py-2
              "
            >
              Unlock
            </button>
          </div>
        </form>
      </div>
    </div>
  </Modal>
</template>
<script>
import { useStore } from "vuex";
import { ref, computed } from "vue";
import Modal from "@/components/Modal.vue";
export default {
  components: { Modal },
  setup() {
    const store = useStore();
    const User = computed(() => store.state.auth.userData);
    const Fullname = computed(() => User.value.fullname);
    const Password = ref("");
    const UnlockScreen = () => {
      if (!Password.value) {
        store.dispatch("showAlert", {
          title: "Error",
          message: "Please enter your password",
          status: "error",
          showing: true,
        });
        return;
      }
      store.dispatch("auth/logInUser", {
        username: User.value.email,
        password: Password.value,
        redirect: false,
      });
    };
    const Logout = () => {
      store.dispatch("auth/logOutUser");
    };
    return {
      Fullname,
      User,
      Password,
      UnlockScreen,
      Logout,
    };
  },
};
</script>
