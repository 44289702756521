<template>
  <div class="nk-header-tools">
    <ul class="nk-quick-nav">
      <li class="dropdown chats-dropdown hide-mb-xs">
        <a
          href="#"
          @click.prevent
          class="dropdown-toggle nk-quick-nav-icon"
          data-toggle="dropdown"
        >
          <div class="icon-status icon-status-na">
            <em class="icon ni ni-comments"></em>
          </div>
        </a>
        <div class="dropdown-menu dropdown-menu-xl dropdown-menu-right">
          <div class="dropdown-head">
            <span class="sub-title nk-dropdown-title">Recent Messages</span>
            <a href="#" @click.prevent title="Message Settings"
              ><em class="icon ni ni-opt"></em
            ></a>
          </div>
          <div class="dropdown-body">
            <ul class="chat-list">
              <li class="chat-item">
                <a class="chat-link" href="#" @click.prevent>
                  <div class="chat-media user-avatar">
                    <span>IH</span>
                    <span class="status dot dot-lg dot-gray"></span>
                  </div>
                  <div class="chat-info">
                    <div class="chat-from">
                      <div class="name">Iliash Hossain</div>
                      <span class="time">Now</span>
                    </div>
                    <div class="chat-context">
                      <div class="text">
                        You: Please confrim if you got my last messages.
                      </div>
                      <div class="status delivered">
                        <em class="icon ni ni-check-circle-fill"></em>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <!-- .nk-dropdown-body -->
          <div class="dropdown-foot center">
            <a href="#" @click.prevent>View All</a>
          </div>
        </div>
      </li>
      <li class="dropdown notification-dropdown">
        <a
          href="#"
          @click.prevent
          class="dropdown-toggle nk-quick-nav-icon"
          data-toggle="dropdown"
        >
          <div class="icon-status icon-status-info">
            <em class="icon ni ni-bell"></em>
          </div>
        </a>
        <div class="dropdown-menu dropdown-menu-xl dropdown-menu-right">
          <div class="dropdown-head">
            <span class="sub-title nk-dropdown-title">New Notifications</span>
            <a href="#" @click.prevent>Mark All as Read</a>
          </div>
          <div class="dropdown-body">
            <div class="nk-notification">
              <div class="nk-notification-item dropdown-inner">
                <div class="nk-notification-icon">
                  <em class="icon icon-circle bg-primary-dim ni ni-share"></em>
                </div>
                <div class="nk-notification-content">
                  <div class="nk-notification-text">
                    You shared <span>OneInvoice</span> with you.
                  </div>
                  <div class="nk-notification-time">Just now</div>
                </div>
              </div>
            </div>
            <!-- .nk-notification -->
          </div>
          <div class="dropdown-foot center">
            <a href="#" @click.prevent>View All</a>
          </div>
        </div>
      </li>
      <li v-if="Menu.length" class="dropdown list-apps-dropdown">
        <a
          href="#"
          @click.prevent
          class="dropdown-toggle nk-quick-nav-icon"
          data-toggle="dropdown"
        >
          <div class="icon-status icon-status-na">
            <em class="icon ni ni-menu-circled"></em>
          </div>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <div class="dropdown-body">
            <ul class="list-apps">
              <li>
                <router-link @click="hideSidebar" :to="{ name: 'dashboard' }">
                  <span class="list-apps-media"
                    ><em class="icon ni ni-meter bg-primary text-white"></em>
                  </span>
                  <span class="list-apps-title">Dashboard</span>
                </router-link>
              </li>
              <li>
                <router-link @click="hideSidebar" :to="{ name: 'customers' }">
                  <span class="list-apps-media"
                    ><em class="icon ni ni-cart bg-danger-dim"></em>
                  </span>
                  <span class="list-apps-title">Customers</span>
                </router-link>
              </li>
              <li>
                <router-link @click="hideSidebar" :to="{ name: 'invoices' }">
                  <span class="list-apps-media"
                    ><em class="icon bx bxs-report bg-purple-dim"> </em
                  ></span>
                  <span class="list-apps-title">Invoices</span>
                </router-link>
              </li>
              <li>
                <router-link @click="hideSidebar" :to="{ name: 'estimates' }">
                  <span class="list-apps-media"
                    ><em class="icon bx bx-calculator bg-secondary-dim"></em>
                  </span>
                  <span class="list-apps-title">Estimates</span>
                </router-link>
              </li>
              <li>
                <router-link @click="hideSidebar" :to="{ name: 'items' }">
                  <span class="list-apps-media"
                    ><em class="icon ni ni-cart-fill bg-info-dim"></em>
                  </span>
                  <span class="list-apps-title">Items</span>
                </router-link>
              </li>
              <li>
                <router-link
                  @click="hideSidebar"
                  :to="{ name: 'transactions' }"
                >
                  <span class="list-apps-media"
                    ><em class="icon ni ni-growth-fill bg-success-dim"></em
                  ></span>
                  <span class="list-apps-title">Transactions</span>
                </router-link>
              </li>
            </ul>
          </div>
          <!-- .nk-dropdown-body -->
        </div>
      </li>
      <li class="dropdown user-dropdown">
        <a
          href="#"
          @click.prevent
          class="dropdown-toggle mr-n1"
          data-toggle="dropdown"
        >
          <div class="user-toggle">
            <div class="user-avatar">
              <img
                :src="User.photo_url"
                :alt="User.fullname"
                class="rounded-circle"
              />
            </div>
          </div>
        </a>
        <div class="dropdown-menu dropdown-menu-md dropdown-menu-right">
          <div
            class="dropdown-inner user-card-wrap bg-lighter d-none d-md-block"
          >
            <div class="user-card">
              <div class="user-avatar">
                <img
                  :src="User.photo_url"
                  :alt="User.fullname"
                  class="rounded-circle"
                />
              </div>
              <div class="user-info">
                <span class="lead-text">{{ User.fullname }}</span>
                <span class="sub-text">{{ User.email }} </span>
              </div>
            </div>
          </div>
          <div v-if="Menu.length" class="dropdown-inner">
            <ul class="link-list">
              <li>
                <router-link
                  @click="hideSidebar"
                  :to="{ name: 'settings-profile' }"
                  ><em class="icon ni ni-setting-alt"></em>
                  <span>Preference</span></router-link
                >
              </li>
              <li>
                <a class="dark-switch" href="#" @click.prevent
                  ><em class="icon ni ni-moon"></em><span>Dark Mode</span></a
                >
              </li>
            </ul>
          </div>
          <div v-if="authenticated" class="dropdown-inner">
            <ul class="link-list">
              <li>
                <a @click.prevent="logout" href="#"
                  ><em class="icon ni ni-signout"></em><span>Sign out</span></a
                >
              </li>
            </ul>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  setup() {
    const store = useStore();
    const User = computed(() => store.state.auth.userData);
    const Menu = computed(() => store.getters["auth/userMenu"]);
    const isLoggedIn = computed(() => store.getters["auth/isLoggedIn"]);
    const isMerchantSet = computed(() => store.getters["auth/isMerchantSet"]);
    const authenticated = computed(
      () => isLoggedIn.value && isMerchantSet.value
    );
    const logout = () => {
      store.dispatch("auth/logOutUser");
    };
    const hideSidebar = () => {
      store.dispatch("hideSidebar");
    };
    return {
      User,
      Menu,
      authenticated,
      logout,
      hideSidebar,
    };
  },
};
</script>
