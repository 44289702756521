<template>
  <div class="container-fluid">
    <div class="nk-header-wrap">
      <template v-if="Menu.length">
        <div class="nk-menu-trigger d-xl-none ml-n1">
          <a
            href="#"
            @click.prevent="togglesideMenu"
            class="nk-nav-toggle-3 nk-quick-nav-icon"
            data-target="sidebarMenu"
            ><em class="icon ni ni-menu"></em>
          </a>
        </div>

        <div class="nk-menu-trigger d-none d-xl-block ml-n1">
          <a
            @click.prevent="toggleSidebar"
            href="#"
            class="nk-nav-toggle-2 nk-quick-nav-icon"
            data-target="sidebarMenu"
            ><em class="icon ni ni-menu"></em>
          </a>
        </div>
      </template>
      <div class="nk-header-app-name">
        <!-- <em class="icon ni ni-dashlite bg-purple-dim"></em> -->
        <a href="" class="logo-link">
          <img
            class="logo-light logo-img logo-img-lg"
            src="/images/logo.png"
            srcset="/images/logo2x.png 2x"
            alt="logo"
          />
          <img
            class="logo-dark logo-img logo-img-lg"
            src="/images/logo-dark.png"
            srcset="/images/logo-dark2x.png 2x"
            alt="logo-dark"
          />
        </a>
        <div class="nk-header-app-info">
          <!-- <span class="sub-text pt-2 pb-0 mb-0">{{ appTitle }}</span>
          <span class="lead-text size24 my-0 py-0">{{ appSubTitle }}</span> -->
        </div>
      </div>
      <div class="nk-header-menu is-light">
        <div class="nk-header-menu-inner">
          <!-- Menu -->
          <!-- <ul class="nk-menu nk-menu-main">
              <li class="nk-menu-item">
                <a href="html/index.html" class="nk-menu-link">
                  <span class="nk-menu-text">Overview</span>
                </a>
              </li>
              <li class="nk-menu-item">
                <a href="html/components.html" class="nk-menu-link">
                  <span class="nk-menu-text">Components</span>
                </a>
              </li>
              <li class="nk-menu-item has-sub">
                <a href="#" class="nk-menu-link nk-menu-toggle">
                  <span class="nk-menu-text">Use-Case Panel</span>
                </a>
                <ul class="nk-menu-sub">
                  <li class="nk-menu-item">
                    <a href="/demo2/ecommerce/index.html" class="nk-menu-link"
                      ><span class="nk-menu-text">eCommerce Panel</span></a
                    >
                  </li>
                  <li class="nk-menu-item">
                    <a href="/demo3/apps/file-manager.html" class="nk-menu-link"
                      ><span class="nk-menu-text">File Manangement Panel</span>
                    </a>
                  </li>
                  <li class="nk-menu-item">
                    <a
                      href="/demo4/subscription/index.html"
                      class="nk-menu-link"
                      ><span class="nk-menu-text">Subscription Panel</span></a
                    >
                  </li>
                  <li class="nk-menu-item">
                    <a href="/demo5/crypto/index.html" class="nk-menu-link"
                      ><span class="nk-menu-text">Crypto Buy Sell Panel</span>
                    </a>
                  </li>
                  <li class="nk-menu-item">
                    <a href="/demo6/invest/index.html" class="nk-menu-link"
                      ><span class="nk-menu-text">HYIP Investment Panel</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul> -->
          <!-- Menu -->
        </div>
      </div>
      <AppHeaderTool />
    </div>
  </div>
  <LockScreen v-if="locked" />
  <teleport to="#SidebarOverlayTeleport">
    <div
      v-show="showOverlay"
      class="toggle-overlay"
      style="z-index: 600 !important"
    ></div>
  </teleport>
</template>
<script>
import LockScreen from "@/components/LockScreen.vue";
import AppHeaderTool from "@/components/AppHeaderTools.vue";
import { useStore } from "vuex";
import { computed, ref, watchEffect } from "vue";
export default {
  name: "Header",
  components: {
    LockScreen,
    AppHeaderTool,
  },
  setup() {
    const store = useStore();
    const locked = computed(() => store.getters["auth/isLockSreeen"]);
    watchEffect(() => {
      console.log("locked", locked.value);
    });
    const Menu = computed(() => store.getters["auth/userMenu"]);
    // const logout = () => {
    //   store.dispatch("auth/logOutUser");
    // };
    const lockScreen = () => {
      store.dispatch("auth/LockSreeen");
    };
    const showOverlay = ref(false);
    const SidebarState = computed(() => store.getters.sidebarState);
    const toggleSidebar = () => {
      showOverlay.value = false;
      if (SidebarState.value === "collapsed") {
        store.dispatch("toggleSidebar", "expanded");
      } else {
        store.dispatch("toggleSidebar", "collapsed");
      }
    };

    const togglesideMenu = () => {
      const aside = document.querySelector("#AppSideBarMenu");
      if (aside.classList.contains("toggle-menu-slide-left")) {
        aside.classList.remove("toggle-menu-slide-left");
        showOverlay.value = false;
      } else {
        if (aside.classList.contains("nk-sidebar-inactive"))
          aside.classList.remove("nk-sidebar-inactive");
        aside.classList.add("toggle-menu-slide-left");
        showOverlay.value = true;
      }
    };
    const hidesideMenu = () => {
      const aside = document.querySelector("#AppSideBarMenu");
      if (aside.classList.contains("toggle-menu-slide-left")) {
        aside.classList.remove("toggle-menu-slide-left");
        showOverlay.value = false;
      }
    };

    return {
      Menu,
      locked,
      // logout,
      lockScreen,
      toggleSidebar,
      togglesideMenu,
      showOverlay,
      hidesideMenu,
      SidebarState,
    };
  },
};
</script>
<style>
.toggle-menu-slide-left {
  left: 0;
  transform: translateX(0);
}
</style>

