<template>
  <div v-if="fetching" class="loading_spinner_box">
    <div class="loading_spinner"></div>
  </div>
  <template v-else>
    <div class="nk-block-head nk-block-head-md">
      <div class="nk-block-between-md">
        <div class="nk-block-head-content">
          <h3 class="nk-block-title page-title">Dashboard</h3>
        </div>
        <div class="nk-block-head-content">
          <div class="toggle-wrap nk-block-tools-toggle">
            <ul class="nk-block-tools g-1">
              <li>
                <div class="drodown">
                  <a
                    href="#"
                    class="
                      dropdown-toggle
                      btn btn-white btn-dim btn-outline-light
                    "
                    data-toggle="dropdown"
                  >
                    <em class="d-inline icon ni ni-plus"></em>
                    <span class="d-none d-md-block">Create new...</span>
                    <em
                      class="
                        d-none d-md-block
                        dd-indc
                        w3-text-theme
                        icon
                        ni ni-chevron-right
                      "
                    ></em
                  ></a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <ul class="link-list-opt no-bdr">
                      <li>
                        <a class="nk-block-between-md" href="#"
                          ><span>Estimate</span>
                          <em class="icon ni ni-chevron-right width0"></em
                        ></a>
                      </li>

                      <li>
                        <a class="nk-block-between-md" href="#"
                          ><span>Invoice</span
                          ><em class="icon ni ni-chevron-right width0"></em
                        ></a>
                      </li>
                      <li>
                        <a class="nk-block-between-md" href="#"
                          ><span>Bill</span
                          ><em class="icon ni ni-chevron-right width0"></em
                        ></a>
                      </li>
                      <li>
                        <a class="nk-block-between-md" href="#"
                          ><span>Transaction</span
                          ><em class="icon ni ni-chevron-right width0"></em
                        ></a>
                      </li>
                      <li>
                        <router-link
                          class="nk-block-between-md"
                          :to="{ name: 'products-new' }"
                          ><span>Product</span
                          ><em class="icon ni ni-chevron-right width0"></em
                        ></router-link>
                      </li>
                      <li>
                        <router-link
                          class="nk-block-between-md"
                          :to="{ name: 'services-new' }"
                          ><span>Service</span
                          ><em class="icon ni ni-chevron-right width0"></em
                        ></router-link>
                      </li>
                      <li>
                        <router-link
                          class="nk-block-between-md"
                          :to="{ name: 'customers-new' }"
                          ><span>Customer</span
                          ><em class="icon ni ni-chevron-right width0"></em
                        ></router-link>
                      </li>
                      <li>
                        <router-link
                          class="nk-block-between-md"
                          :to="{ name: 'vendors-new' }"
                          ><span>Vendor</span
                          ><em class="icon ni ni-chevron-right width0"></em
                        ></router-link>
                      </li>
                      <li>
                        <router-link
                          class="nk-block-between-md"
                          :to="{ name: 'manufacturers-new' }"
                          ><span>Manufacturer</span
                          ><em class="icon ni ni-chevron-right width0"></em
                        ></router-link>
                      </li>
                      <li>
                        <router-link
                          class="nk-block-between-md"
                          :to="{ name: 'warehouses-new' }"
                          ><span>Warehouse</span
                          ><em class="icon ni ni-chevron-right width0"></em
                        ></router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li class="nk-block-tools-opt">
                <router-link
                  :to="{ name: 'transactions' }"
                  class="btn btn-primary"
                  ><em class="d-inline icon ni ni-bar-chart"></em>
                  <span class="d-none d-md-block">Payments</span></router-link
                >
              </li>
              <li class="nk-block-tools-opt">
                <router-link
                  :to="{ name: 'reports' }"
                  href="#"
                  @click.prevent
                  class="btn btn-white"
                  ><em class="d-inline icon ni ni-reports"></em>
                  <span class="d-none d-md-block">Reports</span></router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- .nk-block-head -->
    <div class="nk-block">
      <div class="row g-gs">
        <div class="col-md-7 col-lg-7 col-xxl-6">
          <div class="card card-bordered h-100 pb-5">
            <div class="card-inner">
              <div class="card-title-group pb-3 g-2">
                <div class="card-title card-title-md">
                  <h4 class="title">Cash Flow</h4>
                  <p>Cash coming in and going out of your business</p>
                </div>
                <div class="card-tools shrink-0 d-sm-block">
                  <div class="drodown">
                    <a
                      href="#"
                      class="
                        dropdown-toggle
                        btn btn-white btn-dim btn-outline-light
                      "
                      data-toggle="dropdown"
                      ><em class="d-sm-inline icon ni ni-filter"></em>
                      <span class="d-md-inline">This year</span>
                      <em
                        class="dd-indc w3-text-theme icon ni ni-chevron-right"
                      ></em>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right">
                      <ul class="link-list-opt no-bdr">
                        <li>
                          <a href="#"><span>This year</span></a>
                        </li>
                        <li>
                          <a href="#"><span>Last 7 days</span></a>
                        </li>
                        <li>
                          <a href="#"><span>Last 30 days</span></a>
                        </li>
                        <li>
                          <a href="#"><span>Last 12 months</span></a>
                        </li>
                        <li>
                          <a href="#"><span>Last 24 months</span></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="analytic-ov pb-4">
                <div class="analytic-data-group analytic-ov-group g-3">
                  <div class="analytic-data analytic-ov-data width50p">
                    <div class="title">Inflow</div>
                    <div
                      class="amount"
                      v-html="Util.Currency('250700.3')"
                    ></div>
                  </div>
                  <div class="analytic-data analytic-ov-data width50p">
                    <div class="title">Outflow</div>
                    <div
                      class="amount w3-text-red"
                      v-html="Util.Currency('390000')"
                    ></div>
                  </div>
                </div>
              </div>
              <div style="min-height: 300px">
                <canvas
                  id="cash-flow-chart"
                  height="300px"
                  class="p-3"
                ></canvas>
              </div>
            </div>
          </div>
          <!-- .card -->
        </div>
        <div class="col-sm-6 col-md-5 col-lg-5 col-xxl-3">
          <div class="card card-bordered h-100">
            <div class="card-inner">
              <div class="card-title-group align-start g-2">
                <div class="card-title card-title-md">
                  <h4 class="title">Receivables</h4>
                </div>
                <div class="card-tools shrink-0 d-sm-block">
                  <a href="#" @click.prevent class="btn btn-white">
                    <span>View</span>
                    <em
                      class="dd-indc w3-text-theme icon ni ni-chevron-right"
                    ></em>
                  </a>
                </div>
              </div>
              <div class="analytic-ov">
                <div class="analytic-data-group">
                  <div class="analytic-data analytic-ov-data width100p">
                    <div class="title mb-0">Unpaid invoices</div>
                    <div
                      class="
                        amount
                        size18
                        w3-border-top w3-border-bottom
                        size14
                        mt-2
                        py-1
                      "
                      v-html="Util.Currency('9.280,582.75')"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="nk-tb-list d-block">
              <div class="px-4 py-2 w3-hover-light-grey cursor">
                <div class="tb-lead ellipsis">Lotus Capital Limited</div>
                <span
                  class="tb-amount size16 w3-text-theme bold"
                  v-html="Util.Currency('705,000.00')"
                ></span>
              </div>
              <div class="px-4 py-2 w3-hover-light-grey cursor">
                <div class="tb-lead ellipsis">
                  International Health Management Systems (IHMS)
                </div>
                <span
                  class="tb-amount size16 w3-text-theme bold"
                  v-html="Util.Currency('574,437.00')"
                ></span>
              </div>
              <div class="px-4 py-2 w3-hover-light-grey cursor">
                <div class="tb-lead ellipsis">
                  Industrial & General Insurance Plc (IGI)
                </div>
                <span
                  class="tb-amount size16 w3-text-theme bold"
                  v-html="Util.Currency('260,000.00')"
                ></span>
              </div>
              <div class="px-4 py-2 w3-hover-light-grey cursor">
                <div class="tb-lead ellipsis">
                  ROUTE 712 Experiential Agency
                </div>
                <span
                  class="tb-amount size16 w3-text-theme bold"
                  v-html="Util.Currency('165,000.00')"
                ></span>
              </div>
              <div class="px-4 py-2 w3-hover-light-grey cursor">
                <div class="tb-lead ellipsis">
                  Ika Grammar School Old Boys Association
                </div>
                <span
                  class="tb-amount size16 w3-text-theme bold"
                  v-html="Util.Currency('90,000.00')"
                ></span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-5 col-lg-5 col-xxl-3">
          <div class="card card-bordered h-100">
            <div class="card-inner">
              <div class="card-title-group align-start g-2">
                <div class="card-title card-title-md">
                  <h4 class="title">Payables</h4>
                </div>
                <div class="card-tools shrink-0">
                  <a href="#" @click.prevent class="btn btn-white">
                    <span>View</span>
                    <em
                      class="dd-indc w3-text-theme icon ni ni-chevron-right"
                    ></em>
                  </a>
                </div>
              </div>
              <div class="analytic-ov">
                <div class="analytic-data-group">
                  <div class="analytic-data analytic-ov-data width100p">
                    <div class="title mb-0">Your Unpaid Bills</div>
                    <div
                      class="
                        amount
                        size18
                        w3-border-top w3-border-bottom
                        size14
                        mt-2
                        py-1
                      "
                      v-html="Util.Currency('6.500,200.00')"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="nk-tb-list d-block">
              <div class="px-4 py-2 w3-hover-light-grey cursor">
                <div class="tb-lead ellipsis">
                  ROUTE 712 Experiential Agency
                </div>
                <span
                  class="tb-amount size16 w3-text-theme bold"
                  v-html="Util.Currency('165,000.00')"
                ></span>
              </div>
              <div class="px-4 py-2 w3-hover-light-grey cursor">
                <div class="tb-lead ellipsis">
                  Ika Grammar School Old Boys Association
                </div>
                <span
                  class="tb-amount size16 w3-text-theme bold"
                  v-html="Util.Currency('90,000.00')"
                ></span>
              </div>
              <div class="px-4 py-2 w3-hover-light-grey cursor">
                <div class="tb-lead ellipsis">
                  Industrial & General Insurance Plc (IGI)
                </div>
                <span
                  class="tb-amount size16 w3-text-theme bold"
                  v-html="Util.Currency('260,000.00')"
                ></span>
              </div>
              <div class="px-4 py-2 w3-hover-light-grey cursor">
                <div class="tb-lead ellipsis">Lotus Capital Limited</div>
                <span
                  class="tb-amount size16 w3-text-theme bold"
                  v-html="Util.Currency('705,000.00')"
                ></span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-7 col-lg-7 col-xxl-6">
          <div class="card card-bordered h-100">
            <div class="card-inner">
              <div class="card-title-group mb-2">
                <div class="card-title card-title-md">
                  <h4 class="title">Profit And Loss</h4>
                  <p>
                    Income and expenses only (includes unpaid invoices and
                    bills).
                  </p>
                </div>
                <div class="card-tools shrink-0">
                  <div class="drodown">
                    <a
                      href="#"
                      class="
                        dropdown-toggle
                        btn btn-white btn-dim btn-outline-light
                      "
                      data-toggle="dropdown"
                      ><em class="d-inline icon ni ni-filter"></em>
                      <span class="d-inline">Last 12 months</span>
                      <em
                        class="dd-indc w3-text-theme icon ni ni-chevron-right"
                      ></em>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right">
                      <ul class="link-list-opt no-bdr">
                        <li>
                          <a href="#"><span>Last 6 months</span></a>
                        </li>
                        <li>
                          <a href="#"><span>Last 12 months</span></a>
                        </li>
                        <li>
                          <a href="#"><span>Last 24 months</span></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="analytic-ov pb-4">
                <div class="analytic-data-group analytic-ov-group g-3">
                  <div class="analytic-data analytic-ov-data width50p">
                    <div class="title mb-0">Inflow</div>
                    <div
                      class="amount size15"
                      v-html="Util.Currency('250700.3')"
                    ></div>
                  </div>
                  <div class="analytic-data analytic-ov-data width50p">
                    <div class="title mb-0">Outflow</div>
                    <div
                      class="amount size15 w3-text-red"
                      v-html="Util.Currency('390000')"
                    ></div>
                  </div>
                </div>
              </div>
              <div style="min-height: 300px">
                <canvas
                  id="report-profit-and-loss-chart"
                  height="300px"
                  class="p-3"
                ></canvas>
              </div>
            </div>

            <!-- .nk-tb-list -->
          </div>
          <!-- .card -->
        </div>
        <div class="col-sm-6 col-md-5 col-lg-5 col-xxl-3">
          <div class="card card-bordered h-100">
            <div class="card-inner">
              <div class="card-title-group">
                <div class="card-title card-title-lg">
                  <h4 class="title">Inventory</h4>
                </div>
                <div class="card-tools shrink-0">
                  <a href="#" @click.prevent class="btn btn-white">
                    <span>View</span>
                    <em
                      class="dd-indc w3-text-theme icon ni ni-chevron-right"
                    ></em>
                  </a>
                </div>
              </div>
            </div>
            <div class="nk-tb-list w3-border-bottom w3-border-light-grey">
              <div class="nk-tb-item">
                <div class="nk-tb-col w3-text-red">
                  <span class=""><span>Low stock items</span></span>
                </div>
                <div class="nk-tb-col w3-text-red text-right">
                  <span class=""><span>0</span></span>
                </div>
              </div>
              <div class="nk-tb-item">
                <div class="nk-tb-col">
                  <span class=""><span>All items</span></span>
                </div>
                <div class="nk-tb-col text-right">
                  <span class=""><span>2,094</span></span>
                </div>
              </div>
              <div class="nk-tb-item">
                <div class="nk-tb-col">
                  <span class=""><span>All item categories</span></span>
                </div>
                <div class="nk-tb-col text-right">
                  <span class=""><span>18</span></span>
                </div>
              </div>
            </div>
            <div class="card-inner mt-3">
              <div class="card-title-group">
                <div class="card-title card-title-sm w3-border-bottom">
                  <h5 class="title bold w3-text-red">Items low in stock</h5>
                </div>
              </div>
            </div>
            <div v-if="false" class="nk-tb-list">
              <div class="nk-tb-item">
                <div class="nk-tb-col w3-text-red">
                  <span class=""><span>Test Item</span></span>
                </div>
                <div class="nk-tb-col w3-text-red text-right">
                  <span class=""><span>0</span></span>
                </div>
              </div>
            </div>
            <div class="px-4 py-3">
              <div class="w3-text-grey mt-5">
                <em
                  class="icon ni ni-check-circle-cut size64 w3-text-green"
                ></em>
              </div>
              <div class="w3-text-grey">All items are well stocked</div>
            </div>
          </div>
          <!-- .card -->
        </div>
        <div class="col-sm-6 col-md-5 col-lg-5 col-xxl-3">
          <div class="card card-bordered h-100">
            <div class="card-inner">
              <div class="card-title-group align-start g-2">
                <div class="card-title card-title-md">
                  <h4 class="title">Things You Can Do</h4>
                </div>
              </div>
            </div>
            <div class="card-inner">
              <router-link
                to=""
                class="
                  nk-block-between
                  btn
                  w3-white
                  btn-dim btn-outline-light
                  mb-2
                "
              >
                <span class="d-inline">Record a Payment</span>
                <em class="dd-indc w3-text-theme icon ni ni-chevron-right"></em>
              </router-link>
              <router-link
                to=""
                class="
                  nk-block-between
                  btn
                  w3-white
                  btn-dim btn-outline-light
                  mb-2
                "
              >
                <span class="d-inline">Create a new invoice</span>
                <em class="dd-indc w3-text-theme icon ni ni-chevron-right"></em>
              </router-link>
              <router-link
                to=""
                class="
                  nk-block-between
                  btn
                  w3-white
                  btn-dim btn-outline-light
                  mb-2
                "
              >
                <span class="d-inline">Create a new bill</span>
                <em class="dd-indc w3-text-theme icon ni ni-chevron-right"></em>
              </router-link>
              <router-link
                :to="{ name: 'items-new' }"
                class="
                  nk-block-between
                  btn
                  w3-white
                  btn-dim btn-outline-light
                  mb-2
                "
              >
                <span class="d-inline">Add a new item</span>
                <em class="dd-indc w3-text-theme icon ni ni-chevron-right"></em>
              </router-link>
              <router-link
                :to="{ name: 'customers-new' }"
                class="
                  nk-block-between
                  btn
                  w3-white
                  btn-dim btn-outline-light
                  mb-2
                "
              >
                <span class="d-inline">Add a customer</span>
                <em class="dd-indc w3-text-theme icon ni ni-chevron-right"></em>
              </router-link>
              <router-link
                :to="{ name: 'vendors-new' }"
                class="
                  nk-block-between
                  btn
                  w3-white
                  btn-dim btn-outline-light
                  mb-2
                "
              >
                <span class="d-inline">Add a vendor</span>
                <em class="dd-indc w3-text-theme icon ni ni-chevron-right"></em>
              </router-link>
              <router-link
                to=""
                class="
                  nk-block-between
                  btn
                  w3-white
                  btn-dim btn-outline-light
                  mb-2
                "
              >
                <span class="d-inline">Add a new manufacturer</span>
                <em class="dd-indc w3-text-theme icon ni ni-chevron-right"></em>
              </router-link>
              <router-link
                :to="{ name: 'warehouses-new' }"
                class="
                  nk-block-between
                  btn
                  w3-white
                  btn-dim btn-outline-light
                  mb-2
                "
              >
                <span class="d-inline">New Warehouse</span>
                <em class="dd-indc w3-text-theme icon ni ni-chevron-right"></em>
              </router-link>
              <router-link
                :to="{ name: 'manufacturers-new' }"
                class="
                  nk-block-between
                  btn
                  w3-white
                  btn-dim btn-outline-light
                  mb-2
                "
              >
                <span class="d-inline">Invite a collaborator</span>
                <em class="dd-indc w3-text-theme icon ni ni-chevron-right"></em>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-xxl-6">
          <!-- <div class="card card-bordered h-100">
              <div class="card-inner mb-n2">
                <div class="card-title-group">
                  <div class="card-title card-title-sm">
                    <h6 class="title">Browser Used By Users</h6>
                  </div>
                  <div class="card-tools">
                    <div class="drodown">
                      <a
                        href="#"
                        class="
                          dropdown-toggle dropdown-indicator
                          btn btn-sm btn-outline-light btn-white
                        "
                        data-toggle="dropdown"
                        >30 Days</a
                      >
                      <div
                        class="
                          dropdown-menu dropdown-menu-right dropdown-menu-xs
                        "
                      >
                        <ul class="link-list-opt no-bdr">
                          <li>
                            <a href="#"><span>7 Days</span></a>
                          </li>
                          <li>
                            <a href="#"><span>15 Days</span></a>
                          </li>
                          <li>
                            <a href="#"><span>30 Days</span></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-tb-list is-loose">
                <div class="nk-tb-item nk-tb-head">
                  <div class="nk-tb-col"><span>Browser</span></div>
                  <div class="nk-tb-col text-right"><span>Users</span></div>
                  <div class="nk-tb-col"><span>% Users</span></div>
                  <div class="nk-tb-col tb-col-sm text-right">
                    <span>Bounce Rate</span>
                  </div>
                </div>
                <div class="nk-tb-item">
                  <div class="nk-tb-col">
                    <div class="icon-text">
                      <em class="text-primary icon ni ni-globe"></em>
                      <span class="tb-lead">Google Chrome</span>
                    </div>
                  </div>
                  <div class="nk-tb-col text-right">
                    <span class="tb-sub tb-amount"><span>1,641</span></span>
                  </div>
                  <div class="nk-tb-col">
                    <div
                      class="progress progress-md progress-alt bg-transparent"
                    >
                      <div class="progress-bar" data-progress="72.84"></div>
                      <div class="progress-amount">72.84%</div>
                    </div>
                  </div>
                  <div class="nk-tb-col tb-col-sm text-right">
                    <span class="tb-sub">22.62%</span>
                  </div>
                </div>
                <div class="nk-tb-item">
                  <div class="nk-tb-col">
                    <div class="icon-text">
                      <em class="text-danger icon ni ni-globe"></em>
                      <span class="tb-lead">Mozilla Firefox</span>
                    </div>
                  </div>
                  <div class="nk-tb-col text-right">
                    <span class="tb-sub tb-amount"><span>497</span></span>
                  </div>
                  <div class="nk-tb-col">
                    <div
                      class="progress progress-md progress-alt bg-transparent"
                    >
                      <div class="progress-bar" data-progress="7.93"></div>
                      <div class="progress-amount">7.93%</div>
                    </div>
                  </div>
                  <div class="nk-tb-col tb-col-sm text-right">
                    <span class="tb-sub">20.49%</span>
                  </div>
                </div>
                <div class="nk-tb-item">
                  <div class="nk-tb-col">
                    <div class="icon-text">
                      <em class="text-info icon ni ni-globe"></em>
                      <span class="tb-lead">Safari Browser</span>
                    </div>
                  </div>
                  <div class="nk-tb-col text-right">
                    <span class="tb-sub tb-amount"><span>187</span></span>
                  </div>
                  <div class="nk-tb-col">
                    <div
                      class="progress progress-md progress-alt bg-transparent"
                    >
                      <div class="progress-bar" data-progress="4.87"></div>
                      <div class="progress-amount">4.87%</div>
                    </div>
                  </div>
                  <div class="nk-tb-col tb-col-sm text-right">
                    <span class="tb-sub">21.34%</span>
                  </div>
                </div>
                <div class="nk-tb-item">
                  <div class="nk-tb-col">
                    <div class="icon-text">
                      <em class="text-orange icon ni ni-globe"></em>
                      <span class="tb-lead">UC Browser</span>
                    </div>
                  </div>
                  <div class="nk-tb-col text-right">
                    <span class="tb-sub tb-amount"><span>96</span></span>
                  </div>
                  <div class="nk-tb-col">
                    <div
                      class="progress progress-md progress-alt bg-transparent"
                    >
                      <div class="progress-bar" data-progress="2.46"></div>
                      <div class="progress-amount">2.46%</div>
                    </div>
                  </div>
                  <div class="nk-tb-col tb-col-sm text-right">
                    <span class="tb-sub">20.33%</span>
                  </div>
                </div>
                <div class="nk-tb-item">
                  <div class="nk-tb-col">
                    <div class="icon-text">
                      <em class="text-blue icon ni ni-globe"></em>
                      <span class="tb-lead">Edge / IE</span>
                    </div>
                  </div>
                  <div class="nk-tb-col text-right">
                    <span class="tb-sub tb-amount"><span>28</span></span>
                  </div>
                  <div class="nk-tb-col">
                    <div
                      class="progress progress-md progress-alt bg-transparent"
                    >
                      <div class="progress-bar" data-progress="1.14"></div>
                      <div class="progress-amount">1.14%</div>
                    </div>
                  </div>
                  <div class="nk-tb-col tb-col-sm text-right">
                    <span class="tb-sub">21.34%</span>
                  </div>
                </div>
                <div class="nk-tb-item">
                  <div class="nk-tb-col">
                    <div class="icon-text">
                      <em class="text-purple icon ni ni-globe"></em>
                      <span class="tb-lead">Other Browser</span>
                    </div>
                  </div>
                  <div class="nk-tb-col text-right">
                    <span class="tb-sub tb-amount"><span>683</span></span>
                  </div>
                  <div class="nk-tb-col">
                    <div
                      class="progress progress-md progress-alt bg-transparent"
                    >
                      <div class="progress-bar" data-progress="10.76"></div>
                      <div class="progress-amount">10.76%</div>
                    </div>
                  </div>
                  <div class="nk-tb-col tb-col-sm text-right">
                    <span class="tb-sub">20.49%</span>
                  </div>
                </div>
              </div>
            </div> -->
        </div>
        <!-- .col -->
        <div class="col-md-6 col-xxl-3">
          <!-- <div class="card card-bordered h-100">
              <div class="card-inner mb-n2">
                <div class="card-title-group">
                  <div class="card-title card-title-sm">
                    <h6 class="title">Pages View by Users</h6>
                  </div>
                  <div class="card-tools">
                    <div class="drodown">
                      <a
                        href="#"
                        class="
                          dropdown-toggle dropdown-indicator
                          btn btn-sm btn-outline-light btn-white
                        "
                        data-toggle="dropdown"
                        >30 Days</a
                      >
                      <div
                        class="
                          dropdown-menu dropdown-menu-right dropdown-menu-xs
                        "
                      >
                        <ul class="link-list-opt no-bdr">
                          <li>
                            <a href="#"><span>7 Days</span></a>
                          </li>
                          <li>
                            <a href="#"><span>15 Days</span></a>
                          </li>
                          <li>
                            <a href="#"><span>30 Days</span></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-tb-list is-compact">
                <div class="nk-tb-item nk-tb-head">
                  <div class="nk-tb-col"><span>Page</span></div>
                  <div class="nk-tb-col text-right">
                    <span>Page Views</span>
                  </div>
                </div>
                <div class="nk-tb-item">
                  <div class="nk-tb-col">
                    <span class="tb-sub"><span>/</span></span>
                  </div>
                  <div class="nk-tb-col text-right">
                    <span class="tb-sub tb-amount"><span>2,879</span></span>
                  </div>
                </div>
                <div class="nk-tb-item">
                  <div class="nk-tb-col">
                    <span class="tb-sub"
                      ><span>/subscription/index.html</span></span
                    >
                  </div>
                  <div class="nk-tb-col text-right">
                    <span class="tb-sub tb-amount"><span>2,094</span></span>
                  </div>
                </div>
                <div class="nk-tb-item">
                  <div class="nk-tb-col">
                    <span class="tb-sub"><span>/general/index.html</span></span>
                  </div>
                  <div class="nk-tb-col text-right">
                    <span class="tb-sub tb-amount"><span>1,634</span></span>
                  </div>
                </div>
                <div class="nk-tb-item">
                  <div class="nk-tb-col">
                    <span class="tb-sub"><span>/crypto/index.html</span></span>
                  </div>
                  <div class="nk-tb-col text-right">
                    <span class="tb-sub tb-amount"><span>1,497</span></span>
                  </div>
                </div>
                <div class="nk-tb-item">
                  <div class="nk-tb-col">
                    <span class="tb-sub"><span>/invest/index.html</span></span>
                  </div>
                  <div class="nk-tb-col text-right">
                    <span class="tb-sub tb-amount"><span>1,349</span></span>
                  </div>
                </div>
                <div class="nk-tb-item">
                  <div class="nk-tb-col">
                    <span class="tb-sub"
                      ><span>/subscription/profile.html</span></span
                    >
                  </div>
                  <div class="nk-tb-col text-right">
                    <span class="tb-sub tb-amount"><span>984</span></span>
                  </div>
                </div>
                <div class="nk-tb-item">
                  <div class="nk-tb-col">
                    <span class="tb-sub"
                      ><span>/general/index-crypto.html</span></span
                    >
                  </div>
                  <div class="nk-tb-col text-right">
                    <span class="tb-sub tb-amount"><span>879</span></span>
                  </div>
                </div>
                <div class="nk-tb-item">
                  <div class="nk-tb-col">
                    <span class="tb-sub"
                      ><span>/apps/messages/index.html</span></span
                    >
                  </div>
                  <div class="nk-tb-col text-right">
                    <span class="tb-sub tb-amount"><span>598</span></span>
                  </div>
                </div>
                <div class="nk-tb-item">
                  <div class="nk-tb-col">
                    <span class="tb-sub"
                      ><span>/general/index-crypto.html</span></span
                    >
                  </div>
                  <div class="nk-tb-col text-right">
                    <span class="tb-sub tb-amount"><span>436</span></span>
                  </div>
                </div>
              </div>
            </div> -->
        </div>
        <div class="col-md-6 col-xxl-3">
          <!-- <div class="card card-bordered h-100">
              <div class="card-inner h-100 stretch flex-column">
                <div class="card-title-group">
                  <div class="card-title card-title-sm">
                    <h6 class="title">Sessions by Device</h6>
                  </div>
                  <div class="card-tools">
                    <div class="drodown">
                      <a
                        href="#"
                        class="
                          dropdown-toggle dropdown-indicator
                          btn btn-sm btn-outline-light btn-white
                        "
                        data-toggle="dropdown"
                        >30 Days</a
                      >
                      <div
                        class="
                          dropdown-menu dropdown-menu-right dropdown-menu-xs
                        "
                      >
                        <ul class="link-list-opt no-bdr">
                          <li>
                            <a href="#"><span>7 Days</span></a>
                          </li>
                          <li>
                            <a href="#"><span>15 Days</span></a>
                          </li>
                          <li>
                            <a href="#"><span>30 Days</span></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="device-status my-auto">
                  <div class="device-status-ck">
                    <canvas
                      class="analytics-doughnut"
                      id="deviceStatusData"
                    ></canvas>
                  </div>
                  <div class="device-status-group">
                    <div class="device-status-data">
                      <em data-color="#798bff" class="icon ni ni-monitor"></em>
                      <div class="title">Desktop</div>
                      <div class="amount">84.5%</div>
                      <div class="change up text-danger">
                        <em class="icon ni ni-arrow-long-up"></em>4.5%
                      </div>
                    </div>
                    <div class="device-status-data">
                      <em data-color="#baaeff" class="icon ni ni-mobile"></em>
                      <div class="title">Mobile</div>
                      <div class="amount">14.2%</div>
                      <div class="change up text-danger">
                        <em class="icon ni ni-arrow-long-up"></em>133.2%
                      </div>
                    </div>
                    <div class="device-status-data">
                      <em data-color="#7de1f8" class="icon ni ni-tablet"></em>
                      <div class="title">Tablet</div>
                      <div class="amount">1.3%</div>
                      <div class="change up text-danger">
                        <em class="icon ni ni-arrow-long-up"></em>25.3%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          <!-- .card -->
        </div>
        <!-- .col -->
      </div>
      <!-- .row -->
    </div>
  </template>
</template>
<script>
import { ref, onMounted } from "vue";
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
} from "chart.js";

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
);
import { Util } from "@/helpers/utilities";
import { useStore } from "vuex";
import { computed } from "vue";
import tippy from "tippy.js";
import $ from "jquery";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/material.css";
export default {
  components: {},
  setup() {
    const store = useStore();
    const User = computed(() => store.state.auth.userData);
    const title = ref("Dashboard");
    const fetching = ref(false);
    const greeting = () => {
      var greeting = "";
      var hour = new Date().getHours();
      if (hour < 12) {
        greeting = "Good morning!";
      }
      if (hour > 12) {
        greeting = "Good afternoon!";
      }
      if (hour > 18) {
        greeting = "Good evening!";
      }
      return greeting;
    };
    const equalColumns = () => {
      Util.equalHeightColumns();
    };

    onMounted(() => {
      renderCharts();
      $(".tooltip").each(function () {
        let options = {
          content: $(this).attr("title"),
        };
        if ($(this).data("placement") !== undefined) {
          options.placement = $(this).data("placement");
        } else {
          options.placement = "right";
        }
        $(this).removeAttr("title");
        tippy(this, {
          animation: "shift-away",
          theme: "material",
          ...options,
        });
      });
    });
    const renderCharts = async () => {
      await renderReportLineChart();
      await renderProfitAndLoss();
      Util.equalHeightColumns();
    };
    const renderReportLineChart = async () => {
      let ctxCashFlow = document
        .getElementById("cash-flow-chart")
        .getContext("2d");
      new Chart(ctxCashFlow, {
        type: "line",
        data: {
          labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          datasets: [
            {
              label: "Inflow",
              data: [
                400, 200, 250, 200, 500, 450, 850, 1050, 950, 1100, 900, 1200,
              ],
              borderWidth: 2,
              borderColor: "#3160D8",
              backgroundColor: "#3160D8",
              pointBorderColor: "#3160D8",
            },
            {
              label: "Outflow",
              data: [
                100, 300, 400, 560, 320, 600, 720, 850, 690, 805, 1200, 1010,
              ],
              borderWidth: 2,
              borderDash: [2, 2],
              borderColor: "#a0afbf",
              backgroundColor: "#a0afbf",
              pointBorderColor: "#a0afbf",
            },
          ],
        },
        options: {
          responsive: true,
          legend: {
            display: true,
          },
          scales: {
            xAxes: {
              ticks: {
                fontSize: "12",
                fontColor: "#777777",
              },
              gridLines: {
                display: false,
              },
            },

            yAxes: {
              ticks: {
                fontSize: "12",
                fontColor: "#777777",
                callback: function (value) {
                  return "₦" + value;
                },
              },
              gridLines: {
                color: "#D8D8D8",
                zeroLineColor: "#D8D8D8",
                borderDash: [2, 2],
                zeroLineBorderDash: [2, 2],
                drawBorder: false,
              },
            },
          },
        },
      });
    };
    const renderProfitAndLoss = async () => {
      let ctxProfitAndLoss = document
        .getElementById("report-profit-and-loss-chart")
        .getContext("2d");
      new Chart(ctxProfitAndLoss, {
        type: "bar",
        data: {
          labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          datasets: [
            {
              label: "Profile/Loss",
              data: [
                -250, -50, 400, 500, -250, 350, 700, 750, 550, 500, -100, 800,
              ],
              backgroundColor: [
                "#ff0000",
                "#ff0000",
                "#3E885B",
                "#3E885B",
                "#ff0000",
                "#3E885B",
                "#3E885B",
                "#3E885B",
                "#3E885B",
                "#3E885B",
                "#ff0000",
                "#3E885B",
              ],
            },
          ],
        },
        options: {
          responsive: true,
          legend: {
            display: false,
          },
          scales: {
            xAxes: {
              ticks: {
                fontSize: "12",
                fontColor: "#777777",
              },
              gridLines: {
                display: false,
              },
            },
            yAxes: {
              ticks: {
                fontSize: "12",
                fontColor: "#777777",
                callback: function (value) {
                  return "₦" + value;
                },
              },
              gridLines: {
                color: "#D8D8D8",
                zeroLineColor: "#D8D8D8",
                borderDash: [2, 2],
                zeroLineBorderDash: [2, 2],
                drawBorder: false,
              },
            },
          },
        },
      });
    };
    return {
      title,
      Util,
      User,
      greeting,
      equalColumns,
      fetching,
    };
  },
};
</script>
