<template>
  <div class="nk-block-head nk-block-head-md">
    <div class="nk-block-between-md">
      <div class="nk-block-head-content pt-3 pl-3">
        <h6 class="nk-block-title mb-0 size12" v-html="sectiontitle ?? ''"></h6>
        <h4 class="nk-block-title page-title my-0" v-html="title ?? ''"></h4>
        <div v-if="description" class="nk-block-des">
          <p class="mb-0" v-html="description"></p>
        </div>
      </div>
      <div class="nk-block-head-content">
        <div class="toggle-wrap nk-block-tools-toggle">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    sectiontitle: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
  },
};
</script>

