<template>
  <div v-bind="$attrs" class="d-flex align-items-center">
    <img src="/images/loading.gif" :width="size" />
    <div v-if="text" class="fetching-spinner-text size12 ml-2">
      {{ text }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    size: {
      type: Number,
      default: 80,
    },
  },
};
</script>

