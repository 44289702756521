<template>
  <label v-if="label" class="control-label">{{ label }} </label>
  <select
    v-bind="{
      ...$attrs,
      onChange: ($event) => {
        $emit('update:modelValue', $event.target.value);
      },
    }"
  >
    <option
      v-for="option in options"
      :key="option.value"
      :value="option.value"
      :selected="option.value === modelValue.includes(option.value)"
    >
      {{ option.text }}
    </option>
  </select>
</template>
<script>
import { onMounted } from "vue";
import { v4 as uuidv4 } from "uuid";
import { frontendUrl } from "@/helpers/utilities";
import { loadScript } from "vue-plugin-load-script";
export default {
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Array,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  inheritAttrs: false,
  setup(props) {
    const $ = window.$;
    const UUID = props.id ?? uuidv4();
    onMounted(() => {
      loadScript(frontendUrl + "/vendor/jquery/jquery-3.6.0.min.js");
      loadScript(frontendUrl + "/vendor/select2/js/select2.full.min.js")
        .then(() => {
          $("#" + UUID.value).select2({
            theme: "bootstrap",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  },
};
</script>