<template>
  <Notify v-if="notify" />
  <LoadingIndicator v-if="loadingIndicator" />
  <PreLoader v-if="preloader" />
  <AppRoot />
  <Alerts />
  <router-view :key="$route.fullPath" />
</template>
<script>
import AppRoot from "./components/AppRoot.vue";
import Alerts from "./components/ui/Alerts.vue";
import LoadingIndicator from "./components/ui/LoadingIndicator.vue";
import Notify from "./components/ui/Notify.vue";
import PreLoader from "./components/ui/PreLoader.vue";
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  name: "App",
  components: {
    AppRoot,
    Alerts,
    LoadingIndicator,
    PreLoader,
    Notify,
  },
  setup() {
    const store = useStore();
    const preloader = computed(() => store.getters.preloader);
    const notify = computed(() => store.getters.notify);
    const loadingIndicator = computed(() => store.state.loadingIndicator);
    const loadingLogoutIndicator = computed(
      () => store.state.loadingLogoutIndicator
    );
    const loggedIn = computed(() => store.getters["auth/isLoggedIn"]);
    const isMerchantSet = computed(() => store.getters["auth/isMerchantSet"]);
    const authenticated = computed(() => loggedIn.value && isMerchantSet.value);
    return {
      preloader,
      notify,
      loadingIndicator,
      loadingLogoutIndicator,
      loggedIn,
      authenticated,
    };
  },
};
</script>

