<template>
  <teleport to="#modalTeleports">
    <div class="notify-box">
      <template v-if="notify.length">
        <div v-for="note in notify" :key="note.uuid">
          <transition
            name="notify"
            appear
            leave-active-class="notify-leave-active"
          >
            <div
              class="
                w3-left-align
                notify
                px-4
                py-2
                mb-2
                min-width300
                w3-round-large
                relative
              "
              :class="notifyBg(note, 'bg')"
              style="max-width: 95%"
            >
              <div
                class="
                  w3-white
                  p-1
                  w3-border w3-round-xxlarge
                  drop-shadow-tiny
                  absolute
                "
                :class="notifyBg(note, 'bg')"
                style="top: -15px; left: -15px"
              >
                <i
                  class=""
                  :class="[notifyBg(note, 'icon'), notifyBg(note, 'textColor')]"
                ></i>
              </div>
              {{ note.message }}
            </div>
          </transition>
        </div>
      </template>
    </div>
  </teleport>
</template>
<script>
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    const notify = computed(() => store.getters.notify);

    const notifyBg = (note, type) => {
      let bg = "w3-white";
      let icon = "fas fa-check";
      let textColor = "";
      switch (note.status) {
        case "success":
          bg = "w3-green";
          icon = "fas fa-check-circle";
          textColor = "w3-text-green";
          break;
        case "error":
          bg = "w3-red";
          icon = "fas fa-exclamation-circle";
          textColor = "w3-text-green";
          break;
        case "warning":
          bg = "w3-amber";
          icon = "fas fa-exclamation-triangle";
          textColor = "w3-text-amber";
          break;
        case "info":
          bg = "w3-blue";
          icon = "fas fa-info-circle";
          textColor = "w3-text-blue";
          break;
        default:
          bg = "w3-white w3-border";
          icon = "fas fa-check";
          textColor = "";
      }
      return type === "bg" ? bg : type === "textColor" ? textColor : icon;
    };

    return {
      notify,
      notifyBg,
    };
  },
};
</script>
<style>
.notify-box {
  position: fixed;
  z-index: 111111;
  bottom: 15px;
  right: 15px;
}

.notify-enter-active {
  animation: notify 0.6s ease;
}
@keyframes notify {
  0% {
    opacity: 0;
    transform: translateX(calc(100% + 300px));
  }
  50% {
    opacity: 1;
    transform: translateX(0);
  }
  60% {
    opacity: 1;
    transform: translateX(-8px);
  }
  70% {
    opacity: 1;
    transform: translateX(+8px);
  }
  80% {
    opacity: 1;
    transform: translateX(-4px);
  }
  90% {
    opacity: 1;
    transform: translateX(+4px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>
