import { Util } from '@/helpers/utilities.js'
import { UserState as State } from '@/store/store-helper.js'
import router from '../router/index'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    initialized: false,
    completed: {},
    user: {},
    users: []
  },
  mutations: {
    logOut: (state) => {
      for (const property in state) {
        if (property in State) {
          state[property] = State[property]
        } else {
          delete state[property]
        }
      }
    }
  },
  actions: {
    init({ commit, state, rootState }) {
      if (state.initialized) return
      commit('showPreloader', 'Initializing users...', { root: true })
      axios
        .get('/api/users/init/' + rootState.auth.userData.uuid)
        .then((response) => {
          if (response.data.status === 'success') {
            const config = response.data.result
            if (config) {
              for (const cfg in config) {
                state[cfg] = config[cfg]
              }
            }
            state.initialized = true
          } else {
            Util.Error(response.data.result)
          }
        })
        .catch((error) => {
          if (error.response.status === 401)
            commit('auth/logOutUser', { root: true })
          else Util.Error(error.message, 'Error: ' + error.code)
        })
        .finally(() => {
          commit('hidePreloader', null, { root: true })
        })
    },
    GetUsers({ state, commit, rootState }) {
      if (state.users.length > 0) return
      commit('showPreloader', 'fetching users...', { root: true })
      axios
        .get('/api/users/' + rootState.businesses.business.uuid)
        .then((response) => {
          if (response.data.status === 'success') {
            state.users = response.data.result.users
          } else {
            Util.Error(response.data.result)
          }
        })
        .catch((error) => {
          if (error.response.status === 401)
            commit('auth/logOutUser', { root: true })
          else Util.Error(error.message, 'Error: ' + error.code)
        })
        .finally(() => {
          commit('hidePreloader', null, { root: true })
        })
    },
    register({ commit, state }, data) {
      commit('showPreloader', 'Registering your information...', { root: true })
      state.completed.UpdateUser = false
      axios
        .post('/api/users/register', data)
        .then((response) => {
          if (response.data.status === 'success') {
            state.user = response.data.result.user
            state.users = response.data.result.users
            state.completed.UpdateUser = true
            Util.notify({
              message: 'User registered successfully',
              duration: 5,
              status: 'success'
            })
          } else {
            Util.Error(response.data.result)
          }
        })
        .catch((error) => {
          Util.Error(error.message, 'Error: ' + error.code)
          console.log(error.code)
        })
        .finally(() => {
          commit('hidePreloader', null, { root: true })
        })
    },
    auth_register({ commit, dispatch, state }, data) {
      commit('showPreloader', 'Saving user data...', { root: true })
      state.completed.UpdateUser = false
      axios
        .post('/api/auth/register', data)
        .then((response) => {
          if (response.data.status === 'success') {
            state.user = response.data.result.user
            state.users = response.data.result.users
            state.completed.UpdateUser = true
            Util.notify({
              message: 'User registered successfully',
              duration: 5,
              status: 'success'
            })
            dispatch(
              'auth/logInUser',
              { username: data.user.email, password: data.user.password },
              { root: true }
            )
            router.push({ name: 'login' })
          } else {
            Util.Error(response.data.result)
          }
        })
        .catch((error) => {
          Util.Error(error.message, 'Error: ' + error.code)
        })
        .finally(() => {
          commit('hidePreloader', null, { root: true })
        })
    },
    UpdateUser({ commit, state }, data) {
      commit('showPreloader', 'Saving user data...', { root: true })
      state.completed.UpdateUser = false
      if (data.user.uuid) {
        axios
          .put('/api/users/update', data)
          .then((response) => {
            if (response.data.status === 'success') {
              state.user = response.data.result.user
              state.users = response.data.result.users
              state.completed.UpdateUser = true
              Util.notify({
                message: 'User updated successfully',
                duration: 5,
                status: 'success'
              })
            } else {
              Util.Error(response.data.result)
            }
          })
          .catch((error) => {
            if (error.response.status === 401)
              commit('auth/logOutUser', { root: true })
            else Util.Error(error.message, 'Error: ' + error.code)
          })
          .finally(() => {
            commit('hidePreloader', null, { root: true })
          })
      } else {
        axios
          .post('/api/users/register', data)
          .then((response) => {
            if (response.data.status === 'success') {
              state.user = response.data.result.user
              state.users = response.data.result.users
              state.completed.UpdateUser = true
              Util.notify({
                message: 'User registered successfully',
                duration: 5,
                status: 'success'
              })
            } else {
              Util.Error(response.data.result)
            }
          })
          .catch((error) => {
            Util.Error(error.message, 'Error: ' + error.code)
          })
          .finally(() => {
            commit('hidePreloader', null, { root: true })
          })
      }
    },
    SetUser({ state }, user) {
      state.user = user
    },
    UnsetUser({ state }) {
      state.user = {}
    }
  },
  getters: {
    initialized: (state) => state?.initialized,
    completed: (state) => state?.completed,
    user: (state) => state?.user ?? {},
    users: (state) => state?.users ?? []
  }
}
