<template>
  <teleport to="#modalTeleports">
    <div :id="ID" class="w3-modal d-block px-3 pb-5">
      <div
        v-bind="$attrs"
        class="w3-modal-content w3-round-xlarge max-width100p w3-border"
        :style="{
          width: width,
          'background-color': background ? background : 'transparent',
        }"
      >
        <div
          v-if="!nonclosable"
          class="w3-right-align w3-display-topright"
          style="top: -15px; right: 10px"
        >
          <span
            @click.prevent="Close"
            class="
              w3-button w3-circle w3-vivid-red w3-hover-vivid-reddish-orange
            "
            >x</span
          >
        </div>

        <div class="p-0"><slot /></div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { onMounted } from "vue";
import $ from "jquery";
import { v4 as uuidv4 } from "uuid";
export default {
  props: {
    id: {
      type: String,
      default: "",
    },
    nonclosable: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "1024px",
    },
    background: {
      type: String,
      // default: "#ffffff",
    },
    overlay: {
      type: String,
      default: "rgba(255, 255, 255, 0.9)",
    },
  },
  inheritAttrs: false,
  emits: ["close"],
  setup(props, { emit }) {
    const ID = props.id ? props.id : uuidv4();
    const Close = () => {
      emit("close");
    };
    onMounted(() => {
      $("#" + ID + ".w3-modal").css("background-color", props.overlay);
    });
    return { ID, Close };
  },
};
</script>

