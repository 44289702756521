import { computed } from 'vue'
import { useStore } from 'vuex'
export const storeModules = [
  'auth',
  'businesses',
  'customers',
  'merchants',
  'items',
  'users',
  'taxes',
  'vendors',
  'manufacturers',
  'warehouses'
]
export const RootState = {
  initialized: false,
  tick: 0,
  ModalAlert: {},
  loadingIndicator: false,
  indicatorText: '',
  preloader: false,
  preloaderText: '',
  notify: [],
  notifyIntervals: {},
  loadingLogoutIndicator: false,
  sidebarState: 'expanded', // 'collapsed' or 'expanded'
  settings: {},
  appTitle: '',
  appSubTitle: '',
  appVersion: '',
  IdleLockscreenTime: 5, // 5 minutes
  IdleLogoutTime: 15, // 5 minutes
  fetching: {},
  breadcrumb: '',
  countries: {},
  states: []
}
export const AuthState = {
  initialized: false,
  uuid: '',
  muuid: '',
  isLoggedIn: false,
  isMerchantSet: false,
  userData: {},
  userMerchant: {},
  userMenu: [],
  lastActive: null,
  lock: false,
  token: '',
  ttl: '',
  ticks: 0,
  redirect: false
}
export const BusinessState = {
  initialized: false,
  business: {},
  businesses: []
}
export const CustomerState = {
  initialized: false,
  completed: {},
  customer: {},
  customers: []
}
export const MerchantState = {
  initialized: false,
  merchant: {},
  merchants: []
}
export const ItemState = {
  initialized: false,
  completed: {},
  item: {},
  items: []
}
export const ProductState = {
  initialized: false,
  completed: {},
  product: {},
  products: []
}
export const UserState = {
  initialized: false,
  completed: {},
  user: {},
  users: []
}
export const TaxState = {
  initialized: false,
  completed: {},
  tax: {},
  taxes: []
}
export const VendorState = {
  initialized: false,
  completed: {},
  vendor: {},
  vendors: []
}
export const ManufacturerState = {
  initialized: false,
  completed: {},
  vendor: {},
  vendors: []
}
export const WarehouseState = {
  initialized: false,
  completed: {},
  warehouse: {},
  warehouses: []
}
export function useState(arr) {
  const { state } = useStore()
  const keypair = arr.map((s) => [s, computed((s) => state[s])])
  return Object.fromEntries(keypair)
}
export function useGetters(arr) {
  const { getters } = useStore()
  const keypair = arr.map((g) => [g, computed(() => getters[g])])
  return Object.fromEntries(keypair)
}
export function useMutations(arr) {
  const { commit } = useStore()
  const keypair = arr.map((m) => [m, (input) => commit(m, input)])
  return Object.fromEntries(keypair)
}
