<template>
  <teleport to="#modalTeleports">
    <div class="loading_spinner_box">
      <div class="loading_spinner"></div>
      <div
        v-if="indicatorText"
        class="loading_spinner_text w3-content w3-center py-2 px-4 mx-auto"
      >
        {{ indicatorText }}
      </div>
    </div>
  </teleport>
</template>
<script>
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    const indicatorText = computed(() => store.getters.indicatorText);
    return {
      indicatorText,
    };
  },
};
</script>
<style>
@keyframes loading_spinner {
  to {
    transform: rotate(360deg);
  }
}
.loading_logout_spinner_box {
  position: fixed;
  z-index: 111111;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
}
.loading_spinner_box {
  position: fixed;
  z-index: 111111;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
}
.loading_spinner {
  display: block;
  padding: 50px 0;
  /* color: rgb(255, 255, 255); */
}
.loading_spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 40%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin-top: -10px;
  margin-left: -40px;
  border-radius: 50%;
  border: 2px solid #f00;
  border-top-color: #333;
  animation: loading_spinner 0.6s linear infinite;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}
.loading_spinner_text {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.728);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
