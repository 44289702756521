import { Util } from '@/helpers/utilities.js'
import { TaxState as State } from '@/store/store-helper.js'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    initialized: false,
    completed: {},
    tax: {},
    taxes: []
  },
  mutations: {
    logOut: (state) => {
      for (const property in state) {
        if (property in State) {
          state[property] = State[property]
        } else {
          delete state[property]
        }
      }
    }
  },
  actions: {
    async getTax({ state, commit, dispatch, rootState }, uuid) {
      if (!uuid) return
      commit('showPreloader', 'fetching tax...', { root: true })
      rootState.fetching.getTax = true
      return axios
        .get('/api/tax/' + uuid)
        .then((response) => {
          if (response.data.status === 'success') {
            state.tax = response.data.result.tax
            return state.tax
          } else {
            Util.Error(response.data.result)
          }
        })
        .catch((error) => {
          if (error.response?.status === 401)
            commit('auth/logOutUser', { root: true })
          else Util.Error(error.message, 'Error: ' + error.code)
        })
        .finally(() => {
          commit('hidePreloader', null, { root: true })
          dispatch('stopFetching', 'getTax', { root: true })
        })
    },
    async deleteTax({ state, commit, dispatch, rootState }, uuid) {
      if (!uuid) return
      commit('showPreloader', 'deleting tax...', { root: true })
      rootState.fetching.deleteTax = true
      // const data = {
      //   uuid: uuid
      // }
      return axios
        .post('/api/taxes/delete/' + uuid)
        .then((response) => {
          if (response.data.status === 'success') {
            state.taxes = response.data.result.taxes
            return true
          } else {
            Util.Error(response.data.result)
          }
        })
        .catch((error) => {
          if (error.response?.status === 401)
            commit('auth/logOutUser', { root: true })
          else Util.Error(error.message, 'Error: ' + error.code)
        })
        .finally(() => {
          commit('hidePreloader', null, { root: true })
          dispatch('stopFetching', 'deleteTax', { root: true })
        })
    },
    async duplicateTax({ commit, dispatch, rootState }, uuid) {
      if (!uuid) return
      commit('showPreloader', 'fetching tax...', { root: true })
      rootState.fetching.duplicateTax = true
      return axios
        .get('/api/tax/' + uuid)
        .then((response) => {
          if (response.data.status === 'success') {
            return response.data.result.tax
          } else {
            Util.Error(response.data.result)
          }
        })
        .catch((error) => {
          if (error.response?.status === 401)
            commit('auth/logOutUser', { root: true })
          else Util.Error(error.message, 'Error: ' + error.code)
        })
        .finally(() => {
          commit('hidePreloader', null, { root: true })
          dispatch('stopFetching', 'duplicateTax', { root: true })
        })
    },
    updateTax({ commit, state, rootState }, tax) {
      commit('showIndicator', 'Saving tax...', { root: true })
      state.completed.UpdateTax = false
      if (tax.uuid) tax._method = 'put'
      if (!tax.uuid) tax.merchant_uuid = rootState.auth.userMerchant.uuid
      return axios
        .post('/api/taxes/update', tax)
        .then((response) => {
          if (response.data.status === 'success') {
            state.tax = response.data.result.tax
            state.taxes = response.data.result.taxes
            Util.notify({
              message: 'Tax updated successfully',
              duration: 5,
              status: 'success'
            })
            state.completed.UpdateTax = true
            return response.data
          } else {
            Util.Error(response.data.result)
            return null
          }
        })
        .catch((error) => {
          if (error.response.status === 401)
            commit('auth/logOutUser', { root: true })
          else Util.Error(error.message, 'Error: ' + error.code)
          return null
        })
        .finally(() => {
          commit('hideIndicator', null, { root: true })
        })
    },
    setTax({ state }, tax) {
      state.tax = tax
    },
    setTaxes({ state }, taxes) {
      state.taxes = taxes
    },
    unsetTax({ state }) {
      state.tax = {}
    }
  },
  getters: {
    tax: (state) => state.tax ?? {},
    taxes: (state) => state?.taxes ?? [],
    initialized: (state) => state?.initialized,
    completed: (state) => state?.completed
  }
}
