import router from '../router/index'
import { appName, Util } from '@/helpers/utilities.js'
import { AuthState } from '@/store/store-helper.js'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    initialized: false,
    uuid: '',
    muuid: '',
    isLoggedIn: false,
    isMerchantSet: false,
    userData: {},
    userMerchant: {},
    userMenu: [],
    lastActive: null,
    lock: false,
    token: '',
    ttl: '',
    ticks: 0,
    redirect: false,
    trace: 0
  },
  mutations: {
    async logOutUser(state, payload) {
      for (const property in state) {
        if (property in AuthState) {
          state[property] = AuthState[property]
        } else {
          delete state[property]
        }
      }
      await payload.commit('logOut', null, { root: true })
      await payload.commit('businesses/logOut', null, { root: true })
      await payload.commit('customers/logOut', null, { root: true })
      await payload.commit('merchants/logOut', null, { root: true })
      await payload.commit('items/logOut', null, { root: true })
      await payload.commit('users/logOut', null, { root: true })
      await payload.commit('vendors/logOut', null, { root: true })

      localStorage.removeItem(appName + '-locked')
      localStorage.removeItem(appName + '-uuid')
      localStorage.removeItem(appName + '-muuid')
      localStorage.removeItem(appName + '-ttk')
      localStorage.removeItem(appName + '-ttl')
      localStorage.removeItem(appName + '-lr')
      localStorage.removeItem(appName + '-lp')

      state.isLoggedIn = false
      state.isMerchantSet = false

      if (!payload.nodirect) router.go({ name: 'login' })
    }
  },
  actions: {
    trace: ({ state, rootState }, Module) => {
      if (rootState.settings._DEBUG) {
        state.trace++
        console.log(state.trace + ': ' + Module)
      }
    },
    setUserMerchant({ state, dispatch }, merchant) {
      dispatch('trace', 'setUserMerchant')
      state.userMerchant = { ...merchant }
      state.isMerchantSet = true
      //TODO encrype all data before storing in local storage
      localStorage.setItem(appName + '-muuid', merchant.uuid)
    },
    unsetUserMerchant({ state, dispatch }) {
      dispatch('trace', 'unsetUserMerchant')
      state.userMerchant = {}
      state.isMerchantSet = false
      localStorage.removeItem(appName + '-muuid')
    },
    setUserRole: ({ state, dispatch }, role) => {
      dispatch('trace', 'setUserRole')
      state.userData.role = role
    },
    setUserMenu: ({ state, dispatch }, menu) => {
      dispatch('trace', 'setUserMenu')
      state.userMenu = menu
    },
    async logInUser({ state, commit, dispatch, rootState }, loginInfo) {
      dispatch('trace', 'logInUser')
      const username = loginInfo.username
      const password = loginInfo.password
      dispatch('showIndicator', null, { root: true })
      if (Util.isEmpty(rootState.settings))
        await dispatch('init', null, { root: true })
      if (Util.isEmpty(rootState.settings)) return
      return axios
        .post('/api/auth/login', {
          email: username,
          password: password
        })
        .then((response) => {
          if (response.data.status === 'success') {
            dispatch('trace', 'logInUser success')
            const payload = response.data.result
            state.token = payload.token
            state.ttl = payload.ttl
            axios.defaults.headers.common['Authorization'] =
              'Bearer ' + payload.token
            //TODO encrype all data before storing in local storage
            localStorage.setItem(appName + '-ttk', payload.token)
            localStorage.setItem(appName + '-ttl', payload.ttl)
            localStorage.setItem(appName + '-lr', new Date())
            localStorage.setItem(appName + '-uuid', payload.user.uuid)
            // if (state.lock && state.userData.uuid) {
            //   state.userData = {
            //     ...state.userData,
            //     lastActive: new Date()
            //   }
            // } else { }
            state.userData = {
              ...payload.user,
              lastActive: new Date()
            }

            dispatch('UnLockSreeen')
            state.isLoggedIn = true
            state.initialized = true
          } else {
            Util.Error(response.data.result)
          }
        })
        .catch((error) => {
          dispatch('trace', 'logInUser error')
          if (error.response.status === 401) {
            Util.Error('Invalid login')
          } else {
            Util.Error(error.message)
          }
        })
        .finally(() => {
          commit('hideIndicator', null, { root: true })
        })
    },
    switchMerchant({ dispatch }, payload) {
      dispatch('showIndicator', null, {
        root: true
      })
      return dispatch('merchants/init', payload, { root: true })
    },
    refreshToken({ state, dispatch }) {
      dispatch('trace', 'refreshToken')
      axios
        .post('/api/auth/refresh')
        .then((response) => {
          if (response.data.status === 'success') {
            dispatch('trace', 'refreshToken success')
            state.token = response.data.result.token
            state.ttl = response.data.result.ttl
            if (state.token !== '') {
              axios.defaults.headers.common['Authorization'] =
                'Bearer ' + state.token

              //TODO encrype all data before storing in local storage
              localStorage.setItem(appName + '-ttk', state.token)
              localStorage.setItem(appName + '-ttl', state.ttl)
              localStorage.setItem(appName + '-lr', new Date())
            }
          } else {
            dispatch('trace', 'refreshToken error')
            Util.Error(response.data.result)
          }
        })
        .catch((error) => {
          dispatch('trace', 'refreshToken catcherror')
          if (error.response.status === 401) {
            dispatch('logOutUser', true)
          } else {
            Util.Error(error.message)
          }
        })
    },
    async getAuthUser({ state, commit, dispatch }) {
      dispatch('trace', 'getAuthUser')
      dispatch('showIndicator', null, {
        root: true
      })
      return axios
        .get('/api/auth/me')
        .then(async (response) => {
          if (response.data.status === 'success') {
            dispatch('trace', 'getAuthUser success')
            state.userData = response.data.result.user
            state.isLoggedIn = true
            const merchant = localStorage.getItem(appName + '-muuid')
            if (merchant && Util.isEmpty(state.userMerchant)) {
              await dispatch('switchMerchant', {
                muuid: merchant,
                redirect: false
              })
            } else {
              state.initialized = true
              commit('hideIndicator', null, { root: true })
            }
          } else {
            dispatch('trace', 'getAuthUser error')
            commit('hideIndicator', null, { root: true })
            Util.Error(response.data.result)
          }
        })
        .catch((error) => {
          dispatch('trace', 'getAuthUser catcherror')
          console.log('status', error)
          if (error.response?.status === 401) {
            dispatch('logOutUser')
          } else {
            Util.Error(error.message)
          }
        })
    },
    setStorageData({ state, dispatch }, data) {
      dispatch('trace', 'setStorageData')
      if (!state.uuid && data.uuid) state.uuid = data.uuid
      if (!state.muuid && data.muuid) state.muuid = data.merchant
    },
    async logOutUser({ commit, dispatch, rootState }, nodirect) {
      dispatch('trace', 'logOutUser')
      dispatch('showIndicator', null, { root: true })
      console.log('Logout start')
      console.log('nodirect', nodirect)
      const payload = {
        nodirect: nodirect,
        rootState: rootState,
        commit: commit
      }
      return axios
        .post('/api/auth/logout')
        .then(() => {
          if (nodirect) dispatch('hideIndicator', null, { root: true })
          commit('logOutUser', payload)
        })
        .catch(() => {
          if (nodirect) dispatch('hideIndicator', null, { root: true })
          commit('logOutUser', payload)
        })
    },
    LockSreeen({ state, dispatch }) {
      dispatch('trace', 'LockSreeen')
      if (state.isLoggedIn) {
        dispatch('trace', 'LockSreeen isLoggedIn')
        state.lock = true
        //TODO encrype all data before storing in local storage
        localStorage.setItem(appName + '-locked', 'locked')
      }
    },
    UnLockSreeen({ state, dispatch }) {
      dispatch('trace', 'UnLockSreeen')
      state.lock = false
      localStorage.removeItem(appName + '-locked')
      state.lastActive = new Date()
    },

    async loginUserFromStorage({ state, dispatch }) {
      dispatch('trace', 'loginUserFromStorage')
      if (!state.userData?.uuid) {
        dispatch('trace', 'loginUserFromStorage userData')
        await dispatch('getAuthUser')
      }
    },
    async checkLocalStorage({ state, dispatch, rootState }) {
      dispatch('trace', 'checkLocalStorage')
      dispatch('showIndicator', null, { root: true })
      if (localStorage.getItem(appName + '-sidebar-state') !== null) {
        const sidebarState = localStorage.getItem(appName + '-sidebar-state')
        if (sidebarState !== '') {
          rootState.sidebarState = sidebarState
        } else {
          //TODO encrype all data before storing in local storage
          localStorage.setItem(appName + '-sidebar-state', 'expanded')
          rootState.sidebarState = 'expanded'
        }
      } else {
        //TODO encrype all data before storing in local storage
        localStorage.setItem(appName + '-sidebar-state', 'expanded')
        rootState.sidebarState = 'expanded'
      }
      const uuid = localStorage.getItem(appName + '-uuid')
      if (uuid && !state.userData.uuid) {
        dispatch('trace', 'checkLocalStorage if userData')
        await dispatch('loginUserFromStorage')
      } else {
        dispatch('trace', 'checkLocalStorage not userData')
        dispatch('hideIndicator', null, { root: true })
        state.initialized = true
      }
    },
    checkIdleTime({ dispatch, state, rootState }) {
      dispatch('trace', 'checkIdleTime')
      if (state.isLoggedIn) {
        dispatch('trace', 'checkIdleTime isLoggedIn')
        const lastActive = new Date(state.lastActive)
        const presentTime = new Date()
        const idleInterval =
          (presentTime.getTime() - lastActive.getTime()) / 1000 / 60
        dispatch(
          'trace',
          'checkIdleTime lastActive: ' + Util.get_time(lastActive)
        )
        dispatch(
          'trace',
          'checkIdleTime presentTime: ' + Util.get_time(presentTime)
        )
        dispatch('trace', 'checkIdleTime idleInterval: ' + idleInterval)
        if (
          rootState.settings._ENABLE_AUTOLOGOUT &&
          idleInterval > rootState.IdleLogoutTime
        ) {
          dispatch('trace', 'checkIdleTime if _ENABLE_AUTOLOGOUT')
          if (state.isLoggedIn) dispatch('logOutUser')
        }
        if (
          !state.lock &&
          rootState.settings._ENABLE_AUTOLOCK &&
          idleInterval > rootState.IdleLockscreenTime
        ) {
          dispatch(
            'trace',
            'checkIdleTime if idleInterval > rootState.IdleLockscreenTime'
          )
          dispatch('LockSreeen')
        }
        if (state.ticks % 10 === 0) {
          const ttl = localStorage.getItem(appName + '-ttl')
          const lastRefresh = new Date(localStorage.getItem(appName + '-lr'))
          const presentTime = new Date()
          const timeDiff = Math.round(
            (presentTime.getTime() - lastRefresh.getTime()) / 1000 // Seconds
          )
          const tickInterval = ttl - timeDiff
          // console.log('ttl', ttl)
          // console.log('timeDiff', timeDiff)
          // console.log('tickInterval', tickInterval)
          if (!state.lock && tickInterval < 30) {
            dispatch('refreshToken')
          }
        }
        state.ticks++
        if (state.ticks === 10) state.ticks = 0
      }
    },
    resetIdleTime({ state }) {
      state.lastActive = new Date()
    },
    checkLogin({ dispatch, state }) {
      if (!state.isLoggedIn) dispatch('logOutUser')
    }
  },
  getters: {
    isAuthenticated: (state) => state?.isLoggedIn && state?.isMerchantSet,
    isLoggedIn: (state) => state?.isLoggedIn,
    isMerchantSet: (state) => state?.isMerchantSet,
    user: (state) => state?.userData,
    userMerchant: (state) => state?.userMerchant,
    userMenu: (state) => state?.userMenu,
    isMenu: (state) => (state?.userMenu.length ? true : false),
    moduleName: () => 'Auth',
    isLockSreeen: (state) =>
      state.lock === true || localStorage.getItem(appName + '-locked')
        ? true
        : false
  }
}
