import { computed } from 'vue'
import store from '../store'
import $ from 'jquery'
import DOMPurify from 'dompurify'
import jsZip from 'jszip'
window.JSZip = jsZip
import 'pdfmake'
import 'datatables.net-bs4'
import 'datatables.net-autofill-bs4'
import 'datatables.net-buttons-bs4'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import 'datatables.net-colreorder-bs4'
import 'datatables.net-datetime'
import 'datatables.net-fixedcolumns-bs4'
import 'datatables.net-fixedheader-bs4'
import 'datatables.net-keytable-bs4'
import 'datatables.net-responsive-bs4'
import 'datatables.net-rowgroup-bs4'
import 'datatables.net-rowreorder-bs4'
import 'datatables.net-scroller-bs4'
import 'datatables.net-searchbuilder-bs4'
import 'datatables.net-searchpanes-bs4'
import 'datatables.net-select-bs4'

const url = new URL(window.location.href)
export const appName = 'icsl'
export const frontendUrl = window.location.origin
export const backendURL =
  process.env.NODE_ENV === 'production'
    ? 'https://oneinvoice.ibi-stones.com/cp/public'
    : 'http://' + url.hostname + '/icsl/public'

export const formatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})
const today = new Date()
const YY = today.getFullYear()
const mm = today.getMonth() + 1
const dd = today.getDate()
const MM = mm > 9 ? mm : '0' + mm
const DD = dd > 9 ? dd : '0' + dd
const Today = YY + '-' + MM + '/' + DD
const TodayFormatted = DD + '/' + MM + '/' + YY

export const Util = {
  get_time: (date) => {
    return new Date(date).toLocaleTimeString()
    // const Dates = date.toString.split(' ')
    // return Dates[4] + ':' + Dates[5] + ':' + Dates[6]
  },
  CurrencySymbol: '&#8358;',
  uuid: () => {
    return 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
      }
    )
  },
  isEmptyObject: (obj) => {
    return Object.keys(obj).length === 0
  },
  isEmpty: (obj) => {
    if (typeof obj === 'undefined') return true
    return Object.keys(obj).length === 0
  },
  hideEmail: (email) => {
    return email.replace(/(.{2})(.*)(?=@)/, function (gp1, gp2, gp3) {
      for (let i = 0; i < gp3.length; i++) {
        gp2 += '*'
      }
      return gp2
    })
  },
  Error: (message, title) => {
    if (!message) return
    // if (!title) title = 'Error'
    store.dispatch('showAlert', {
      title: title,
      message: message,
      status: 'error',
      showing: true
    })
  },
  Success: (message, title) => {
    if (!message) return
    if (!title) title = 'Success'
    store.dispatch('showAlert', {
      title: title,
      message: message,
      status: 'success',
      showing: true
    })
  },
  showIndicator: (text) => {
    const indicatorText = text ?? ''
    console.log(indicatorText)
    store.dispatch('showIndicator', indicatorText)
  },
  hideIndicator: () => {
    store.dispatch('hideIndicator')
  },
  shuffleArray: (array) => {
    let currentIndex = array.length,
      randomIndex

    while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex--
      ;[array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex]
      ]
    }
    return array
  },
  formatCurrency: (amount, symbol) => {
    if (!symbol) {
      if (typeof amount === 'string' && amount.includes(',')) return amount
      return formatter.format(amount)
    }
    if (typeof amount === 'string' && amount.includes(',')) {
      return parseFloat(amount.replace(/,/g, '')) < 0
        ? '(' + DOMPurify.sanitize(symbol) + amount + ')'
        : DOMPurify.sanitize(symbol) + amount
    }
    return parseFloat(amount) < 0
      ? '(' +
          DOMPurify.sanitize(symbol) +
          formatter.format(Math.abs(amount)) +
          ')'
      : DOMPurify.sanitize(symbol) + formatter.format(amount)
  },
  Number: (digits = 2) => {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: digits,
      maximumFractionDigits: digits
    })
  },
  Currency: (amount, symbol = '&#8358;') => {
    if (typeof amount === 'string' && amount.includes(',')) {
      return parseFloat(amount.replace(/,/g, '')) < 0
        ? '(' + DOMPurify.sanitize(symbol) + amount + ')'
        : DOMPurify.sanitize(symbol) + amount
    }
    return parseFloat(amount) < 0
      ? '(' +
          DOMPurify.sanitize(symbol) +
          formatter.format(Math.abs(amount)) +
          ')'
      : DOMPurify.sanitize(symbol) + formatter.format(amount)
  },
  formatOn: {
    Focus: (e) => {
      e.target.value = e.target.value.replace(/,/g, '')
    },
    Blur: (e) => {
      if (e.target.value.includes(',')) return
      e.target.value = formatter.format(e.target.value)
    }
  },
  CurrencyFormat: (e) => {
    console.log(e)
    // Focus: (e) => {
    //   e.target.value = e.target.value.replace(/,/g, '')
    // },
    // Blur: (e) => {
    //   if (e.target.value.includes(',')) return
    //   e.target.value = formatter.format(e.target.value)
    // }
  },
  PercentageFormat: (e) => {
    console.log(e)
    // Focus: (e) => {
    //   e.target.value = e.target.value.replace(/,/g, '')
    // },
    // Blur: (e) => {
    //   if (e.target.value.includes(',')) return
    //   e.target.value = formatter.format(e.target.value)
    // }
  },
  nl2br: (str, is_xhtml) => {
    if (typeof str === 'undefined' || str === null) {
      return ''
    }
    var breakTag =
      is_xhtml || typeof is_xhtml === 'undefined' ? '<br />' : '<br>'
    return (str + '').replace(
      /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
      '$1' + breakTag + '$2'
    )
  },
  shortDate: (inputDate) => {
    // input => yyyy-mm-dd
    // output => dd/mm/yyyy
    if (inputDate?.includes('/')) return inputDate
    const fomattedDate = inputDate?.substring(0, 10)?.split('-')
    if (!fomattedDate || fomattedDate[0] < 1980) return '-'
    return fomattedDate[2] + '/' + fomattedDate[1] + '/' + fomattedDate[0]
  },
  dbDate: (inputDate) => {
    // input => dd/mm/yyyy
    // input => yyyy-mm-dd
    if (inputDate.includes('-')) return inputDate
    const fomattedDate = inputDate?.split('/')
    return fomattedDate[2] + '-' + fomattedDate[1] + '-' + fomattedDate[0]
  },
  queryParameter: () => {
    let uri = window.location.href.split('?')
    if (uri.length == 2) {
      let vars = uri[1].split('&')
      let parameters = {}
      let tmp = ''
      vars.forEach(function (v) {
        tmp = v.split('=')
        if (tmp.length == 2) parameters[tmp[0]] = tmp[1]
      })
      return parameters
    }
    return false
  },
  equalHeightColumns: () => {
    let isMobile = window.matchMedia(
      'only screen and (max-width: 760px)'
    ).matches
    if (!isMobile) {
      $('.equalHeightColumns').each(function () {
        var highestBox = 0
        $('.column', this).each(function () {
          if ($(this).height() > highestBox) {
            highestBox = $(this).height()
          }
        })
        $('.column', this).height(highestBox)
      })
    }
  },
  openModal: (id) => {
    document.getElementById(id).style.display = 'block'
  },
  closeModal: (id) => {
    document.getElementById(id).style.display = 'none'
  },
  removeArrayItem: (arr, item) => {
    const index = arr.indexOf(item)
    if (index > -1) {
      arr.splice(index, 1)
    }
  },
  BuildDataTable: (el, _config) => {
    const defaults = {
      retrieve: true,
      responsive: true,
      dom: '<"row mb-2"<"col-sm-3 mb-1 w3-mobile DT-select"l><"col-sm-4 mb-1 w3-mobile"B><"col-sm-5 mb-1 w3-mobile"<"DT-search"f>>><"row"<"col"t>><"row"<"col-sm-6"i><"col-sm-6 w3-right-align"p>>',
      buttons: [
        {
          extend: 'copy',
          text: "<i class='bx bx-copy-alt' title='Copy to Clipboard' ></i>",
          className: 'w3-button w3-border w3-white px-1 pt-1 size18 pb-0'
        },
        {
          extend: 'excelHtml5',
          text: "<i class='bx bxs-file-export' title='Export to excel' ></i>",
          className: 'w3-button w3-border w3-white px-1 pt-1 size18 pb-0'
        },
        {
          extend: 'pdf',
          text: "<i class='bx bxs-file-pdf' title='Export to PDF'></i>",
          className: 'w3-button w3-border w3-white px-1 pt-1 size18 pb-0'
        },
        {
          extend: 'print',
          text: "<i class='bx bx-printer' title='Print' ></i>",
          className: 'w3-button w3-border w3-white px-1 pt-1 size18 pb-0'
        }
      ],
      pageLength: 25,
      lengthMenu: [
        [5, 10, 15, 25, 50, 100, 200, -1],
        [5, 10, 15, 25, 50, 100, 200, 'All']
      ]
    }
    const opt =
      typeof _config !== 'undefined'
        ? {
            ...defaults,
            ..._config
          }
        : defaults
    const table = $(el).DataTable(opt)
    $(el)
      .find('.searchField')
      .on('keyup', () => {
        table.search($(this).val()).draw()
      })
    return table
  },
  BuildDataTableWithPadding: (el, _config) => {
    const defaults = {
      retrieve: true,
      responsive: true,
      dom: '<"w3-theme-l5 p-3"<"row"<"col-sm-4 mb-1 w3-mobile DT-select"l><"col-sm-4 mb-1 w3-mobile"B><"col-sm-4 mb-1 w3-mobile"<"DT-search"f>>>><"row"<"col"t>><"w3-theme-l5 p-3"<"row"<"col-sm-6"i><"col-sm-6 w3-right-align"p>>>',
      buttons: [
        {
          extend: 'copy',
          text: "<i class='bx bx-copy-alt' title='Copy to Clipboard' ></i>",
          className: 'w3-button w3-border w3-white px-1 pt-1 size18 pb-0'
        },
        {
          extend: 'excelHtml5',
          text: "<i class='bx bxs-file-export' title='Export to excel' ></i>",
          className: 'w3-button w3-border w3-white px-1 pt-1 size18 pb-0'
        },
        {
          extend: 'pdf',
          text: "<i class='bx bxs-file-pdf' title='Export to PDF'></i>",
          className: 'w3-button w3-border w3-white px-1 pt-1 size18 pb-0'
        },
        {
          extend: 'print',
          text: "<i class='bx bx-printer' title='Print' ></i>",
          className: 'w3-button w3-border w3-white px-1 pt-1 size18 pb-0'
        }
      ],
      pageLength: 25,
      lengthMenu: [
        [5, 10, 15, 25, 50, 100, 200, -1],
        [5, 10, 15, 25, 50, 100, 200, 'All']
      ]
    }
    const opt =
      typeof _config !== 'undefined'
        ? {
            ...defaults,
            ..._config
          }
        : defaults
    const table = $(el).DataTable(opt)
    $(el)
      .find('.searchField')
      .on('keyup', () => {
        table.search($(this).val()).draw()
      })
    return table
  },
  scrollToTop: (offset) => {
    offset = typeof offset !== 'undefined' ? parseInt(offset) : 0
    window.scroll({ top: offset, behavior: 'smooth' })
  },
  scrollTo: (elem, offset) => {
    offset = typeof offset !== 'undefined' ? parseInt(offset) : 0
    window.scroll({ top: $(elem).offset().top - offset, behavior: 'smooth' })
  },
  Today: Today,
  TodayFormatted: TodayFormatted,
  ErrorMessage: (error, message) => {
    if (error.status === 'expired') {
      Util.RedirectPage(error.result)
      return false
    } else {
      const Message =
        typeof message !== 'undefined' && message !== ''
          ? message
          : error.result
      return Message
    }
  },
  RedirectPage: (url) => {
    if (typeof url !== 'undefined' && url !== '') {
      window.location = url
    }
  },
  ShowError: (err) => {
    if (err.response) {
      store.dispatch('showAlert', {
        title: 'Error ' + err.response.status + ': ' + err.response.statusText,
        message: err.response.data.result ?? err.response.data.message,
        status: 'error',
        showing: true
      })
    } else {
      store.dispatch('showAlert', {
        title: 'Unknown Error',
        message: 'An unknown error occured',
        status: 'error',
        showing: true
      })
    }
  },
  CatchError: (err) => {
    if (err) {
      let message = ''
      let statusText = ''
      if (err.data.error) {
        message = err.data.error.message
        if (err.statusText) statusText = ' Error: ' + err.data.error.code
      } else if (err.data.message) {
        message = err.data.message
      }

      if (err.status && (err.status < 200 || err.status >= 300)) {
        if (err.statusText)
          statusText = ' ' + err.status + ': ' + err.statusText
      }
      const error = {
        message: message,
        status: 'error',
        showing: true
      }
      if (statusText) error.title = statusText
      store.dispatch('showAlert', error)
    } else {
      store.dispatch('showAlert', {
        title: 'Unknown Error',
        message: 'An unknown error occured',
        status: 'error',
        showing: true
      })
    }
  },
  notify: (data) => {
    store.dispatch('notify', {
      message: data.message,
      duration: data.duration,
      status: data.status
    })
  },
  togglePassword: () => {
    if (
      document.getElementById('password').attributes.type.value === 'password'
    ) {
      document.getElementById('password').attributes.type.value = 'text'
    } else {
      document.getElementById('password').attributes.type.value = 'password'
    }
  },
  menuChildren: (menu_name) => {
    const _Menu = computed(() => {
      const userMenu = store.getters['auth/userMenu']
      let _menu = []
      userMenu.forEach((item) => {
        if (item.name === menu_name) _menu = item.children
      })
      return _menu
    })
    return _Menu
  },
  fetching: (item) => {
    const Fetching = store.state.fetching
    return Fetching[item]
  }
}
