<template>
  <div v-if="Menu.length" class="nk-sidebar-inner" data-simplebar>
    <ul class="nk-menu nk-menu-lg">
      <li class="nk-menu-heading mb-3">
        <h6 class="overline-title text-primary-alt">APP MENU</h6>
      </li>
      <li class="nk-menu-item w3-border-top w3-border-light-grey">
        <router-link
          class="nk-menu-link py-1"
          @click="hideSidebar"
          title="Dashboard"
          :to="{ name: 'dashboard' }"
        >
          <span class="nk-menu-icon mr-2">
            <em class="size24 icon ni ni-dashboard" aria-hidden="true"></em>
          </span>
          <span class="nk-menu-text size15"> Dashboard </span>
          <em class="dd-indc w3-text-light-grey icon ni ni-chevron-right"></em>
        </router-link>
      </li>
      <template v-if="Menu.length">
        <template v-for="menu in Menu" :key="menu.name">
          <li
            v-if="menu.name !== 'dashboard'"
            :key="menu.name"
            class="nk-menu-item w3-border-top w3-border-light-grey"
            :class="[menu.children.length > 0 ? 'has-sub' : '']"
          >
            <a
              v-if="!menu.name || menu.children.length > 0"
              class="nk-menu-link py-1"
              :class="[menu.children.length > 0 ? 'nk-menu-toggle' : '']"
              :title="menu.title"
              href="#"
              @click.prevent="ToggleChildren(menu)"
            >
              <span v-if="menu.icon" class="nk-menu-icon mr-2"
                ><em class="size24" :class="[menu.icon]"></em>
              </span>
              <span class="nk-menu-text size15">{{ menu.title }}</span>
            </a>
            <router-link
              v-else
              class="nk-menu-link py-1"
              @click="hideSidebar"
              :title="menu.title"
              :to="
                menu.name
                  ? {
                      name:
                        menu.name === 'settings'
                          ? 'settings-profile'
                          : menu.name,
                    }
                  : ''
              "
            >
              <span v-if="menu.icon" class="nk-menu-icon mr-2">
                <em class="size24" :class="[menu.icon]" aria-hidden="true"></em>
              </span>
              <span class="nk-menu-text size15">
                {{ menu.title }}
              </span>
              <em
                class="dd-indc icon ni ni-chevron-right"
                :class="[
                  menu.children.length > 0
                    ? 'w3-text-theme'
                    : 'w3-text-light-grey',
                ]"
              ></em>
            </router-link>

            <ul
              :class="'children-' + menu.name"
              v-if="menu.children.length > 0"
              class="nk-menu-sub p-0 mt-0"
            >
              <li
                v-for="(childmenu, i) in menu.children"
                :key="childmenu.name"
                class="nk-menu-item"
                :class="[
                  i < menu.children.length
                    ? 'w3-border-top w3-border-light-grey'
                    : '',
                ]"
              >
                <router-link
                  class="nk-menu-link pr-0"
                  @click="hideSidebar"
                  :title="childmenu.title"
                  :to="
                    childmenu.name
                      ? {
                          name:
                            childmenu.name === 'settings'
                              ? 'settings-profile'
                              : childmenu.name,
                        }
                      : ''
                  "
                >
                  <span v-if="childmenu.icon" class="nk-menu-icon mr-2">
                    <em
                      class="size20"
                      :class="[childmenu.icon]"
                      aria-hidden="true"
                    ></em>
                  </span>
                  <span class="nk-menu-text size14">
                    {{ childmenu.title }}
                  </span>
                </router-link>
              </li>
            </ul>
          </li>
        </template>
      </template>
    </ul>
    <!-- .nk-menu -->
  </div>
</template>
<script>
import { useStore } from "vuex";
import { computed } from "vue";
import $ from "jquery";
import useMenu from "@/composables/use-menu";

export default {
  name: "AppSideBarMenu",
  setup() {
    const store = useStore();
    const appTitle = computed(() => store.state.appTitle);
    const { Menu } = useMenu();
    const logout = () => {
      store.dispatch("auth/logOutUser");
    };
    const hideSidebar = () => {
      store.dispatch("hideSidebar");
    };
    const ToggleChildren = (menu) => {
      if (menu.children.length) {
        const subMenu = $(".nk-menu-sub.children-" + menu.name);
        subMenu.slideToggle();
        // if (subMenu.is(":visible")) subMenu.toggleSlide();
        // else subMenu.slideUp();
      }
    };
    return {
      logout,
      Menu,
      ToggleChildren,
      hideSidebar,
      appTitle,
    };
  },
};
</script>
