<template>
  <div class="nk-apps-brand">
    <a href="" class="logo-link">
      <img
        class="logo-light logo-img"
        src="/images/logo-small.png"
        srcset="/images/logo-small2x.png 2x"
        alt="logo"
      />
      <img
        class="logo-dark logo-img"
        src="/images/logo-dark-small.png"
        srcset="/images/logo-dark-small2x.png 2x"
        alt="logo-dark"
      />
    </a>
  </div>
  <div class="nk-sidebar-element">
    <div class="nk-sidebar-body">
      <div class="nk-sidebar-content" data-simplebar>
        <div class="nk-sidebar-menu">
          <ul class="nk-menu apps-menu">
            <li class="nk-menu-item">
              <router-link
                class="nk-menu-link size18 tooltip"
                @click="hideSidebar"
                title="Dashboard"
                :to="{ name: 'dashboard' }"
              >
                <span class="nk-menu-icon">
                  <em
                    class="size24 icon ni ni-dashboard"
                    aria-hidden="true"
                  ></em>
                </span>
                <span class="nk-menu-text"> Dashboard </span>
              </router-link>
            </li>
            <template v-if="Menu.length">
              <template v-for="menu in Menu" :key="menu.name">
                <li
                  v-if="menu.name !== 'dashboard'"
                  class="nk-menu-item"
                  :class="[menu.children.length > 0 ? 'has-sub' : '']"
                >
                  <a
                    v-if="!menu.name"
                    class="nk-menu-link size18 tooltip"
                    :title="menu.title"
                    href="#"
                  >
                    <span v-if="menu.icon" class="nk-menu-icon"
                      ><em class="text-xl" :class="[menu.icon]"></em
                    ></span>
                    <span class="nk-menu-text">{{ menu.title }}</span>
                  </a>
                  <router-link
                    v-else
                    @click="hideSidebar"
                    class="nk-menu-link size18 tooltip"
                    :title="menu.title"
                    :to="
                      menu.name
                        ? {
                            name:
                              menu.name === 'settings'
                                ? 'settings-profile'
                                : menu.name,
                          }
                        : ''
                    "
                  >
                    <span v-if="menu.icon" class="nk-menu-icon">
                      <em :class="[menu.icon]" aria-hidden="true"></em>
                    </span>
                    <span class="nk-menu-text"> {{ menu.title }}</span>
                  </router-link>
                </li>
              </template>
            </template>
          </ul>
        </div>
        <div v-if="Menu.length" class="nk-sidebar-footer">
          <!-- <ul class="nk-menu nk-menu-md">
            <li class="nk-menu-item">
              <a href="#" class="nk-menu-link" title="Settings">
                <span class="nk-menu-icon"
                  ><em class="icon ni ni-setting"></em
                ></span>
              </a>
            </li>
          </ul> -->
        </div>
      </div>
      <div class="nk-sidebar-profile nk-sidebar-profile-fixed dropdown">
        <a href="#" data-toggle="dropdown" data-offset="50,-60">
          <div class="user-avatar">
            <img
              :src="User.photo_url"
              :alt="User.fullname"
              class="rounded-circle"
            />
          </div>
        </a>
        <div class="dropdown-menu dropdown-menu-md ml-4">
          <div class="dropdown-inner user-card-wrap d-none d-md-block">
            <div class="user-card">
              <div class="user-avatar">
                <img
                  :src="User.photo_url"
                  :alt="User.fullname"
                  class="rounded-circle"
                />
              </div>
              <div class="user-info">
                <span class="lead-text">{{ User.fullname }} </span>
                <span class="sub-text text-soft">{{ User.email }} </span>
              </div>
            </div>
          </div>
          <!-- <div v-if="Menu.length" class="dropdown-inner">
            <ul class="link-list">
              <li>
                <router-link :to="{ name: 'settings-profile' }"
                  ><em class="icon ni ni-setting-alt"></em>
                  <span>Preference</span></router-link
                >
              </li>
            </ul>
          </div> -->
          <div v-if="authenticated" class="dropdown-inner">
            <ul class="link-list">
              <li>
                <a @click.prevent="logout" href="#"
                  ><em class="icon ni ni-signout"></em><span>Sign out</span></a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import useMenu from "@/composables/use-menu";
import { useStore } from "vuex";
import { computed, onMounted } from "vue";
import tippy from "tippy.js";
import $ from "jquery";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/material.css";
export default {
  name: "SideBar",
  setup() {
    const store = useStore();
    const User = computed(() => store.state.auth.userData);
    const SidebarState = computed(() => store.getters.sidebarState);
    const appTitle = computed(() => store.getters.appTitle);
    const { Menu } = useMenu();
    const isLoggedIn = computed(() => store.getters["auth/isLoggedIn"]);
    const isMerchantSet = computed(() => store.getters["auth/isMerchantSet"]);
    const authenticated = computed(
      () => isLoggedIn.value && isMerchantSet.value
    );
    const logout = () => {
      store.dispatch("auth/logOutUser");
    };
    const hideSidebar = () => {
      store.dispatch("hideSidebar");
    };
    onMounted(() => {
      $(".tooltip").each(function () {
        let options = {
          content: $(this).attr("title"),
        };
        if ($(this).data("placement") !== undefined) {
          options.placement = $(this).data("placement");
        } else {
          options.placement = "right";
        }
        $(this).removeAttr("title");
        tippy(this, {
          animation: "shift-away",
          theme: "material",
          ...options,
        });
      });
    });

    return {
      User,
      authenticated,
      logout,
      Menu,
      SidebarState,
      hideSidebar,
      appTitle,
    };
  },
};
</script>
