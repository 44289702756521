import { Util } from '@/helpers/utilities.js'
import { WarehouseState as State } from '@/store/store-helper.js'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    initialized: false,
    completed: {},
    warehouse: {},
    warehouses: []
  },
  mutations: {
    logOut: (state) => {
      for (const property in state) {
        if (property in State) {
          state[property] = State[property]
        } else {
          delete state[property]
        }
      }
    }
  },
  actions: {
    async getWarehouse({ state, commit, dispatch, rootState }, uuid) {
      if (!uuid) return
      commit('showPreloader', 'fetching warehouse...', { root: true })
      rootState.fetching.getWarehouse = true
      return axios
        .get('/api/warehouse/' + uuid)
        .then((response) => {
          if (response.data.status === 'success') {
            state.warehouse = response.data.result.warehouse
            return state.warehouse
          } else {
            Util.Error(response.data.result)
          }
        })
        .catch((error) => {
          if (error.response?.status === 401)
            commit('auth/logOutUser', { root: true })
          else Util.Error(error.message, 'Error: ' + error.code)
        })
        .finally(() => {
          commit('hidePreloader', null, { root: true })
          dispatch('stopFetching', 'getWarehouse', { root: true })
        })
    },
    getWarehouses({ state, commit, rootState }) {
      if (state.warehouses.length > 0) return
      commit('showPreloader', 'fetching warehouses...', { root: true })
      axios
        .get('/api/warehouses/' + rootState.auth.userMerchant.uuid)
        .then((response) => {
          if (response.data.status === 'success') {
            state.warehouses = response.data.result.warehouses
          } else {
            Util.CatchError(response)
          }
        })
        .catch((error) => {
          if (error.response.status === 401)
            commit('auth/logOutUser', { root: true })
          else Util.Error(error.message, 'Error: ' + error.code)
        })
        .finally(() => {
          commit('hidePreloader', null, { root: true })
        })
    },
    updateWarehouse({ commit, state, rootState }, warehouse) {
      commit('showIndicator', 'Saving warehouse...', { root: true })
      state.completed.UpdateWarehouse = false
      if (warehouse.uuid) warehouse._method = 'put'
      if (!warehouse.uuid)
        warehouse.merchant_uuid = rootState.auth.userMerchant.uuid
      return axios
        .post('/api/warehouses/update', warehouse)
        .then((response) => {
          if (response.data.status === 'success') {
            state.warehouse = response.data.result.warehouse
            state.warehouses = response.data.result.warehouses
            Util.notify({
              message: 'Warehouse updated successfully',
              duration: 5,
              status: 'success'
            })
            state.completed.UpdateWarehouse = true
            return response.data
          } else {
            Util.Error(response.data.result)
            return null
          }
        })
        .catch((error) => {
          if (error.response.status === 401)
            commit('auth/logOutUser', { root: true })
          else Util.Error(error.message, 'Error: ' + error.code)
          return null
        })
        .finally(() => {
          commit('hideIndicator', null, { root: true })
        })
    },
    setWarehouse({ state }, warehouse) {
      state.warehouse = warehouse
    },

    unsetWarehouse({ state }) {
      state.warehouse = {}
    },
    setWarehouses({ state }, warehouses) {
      state.warehouses = warehouses
    }
  },
  getters: {
    warehouse: (state) => state?.warehouse ?? {},
    warehouses: (state) => state?.warehouses ?? [],
    initialized: (state) => state?.initialized,
    completed: (state) => state?.completed
  }
}
