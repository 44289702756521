import { createStore } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'
import auth from './auth'
import businesses from './businesses'
import customers from './customers'
import items from './items'
import merchants from './merchants'
import users from './users'
import taxes from './taxes'
import vendors from './vendors'
import manufacturers from './manufacturers'
import warehouses from './warehouses'
import { appName, Util } from '@/helpers/utilities.js'
import { RootState as State, storeModules } from '@/store/store-helper.js'

export default createStore({
  modules: {
    auth,
    businesses,
    customers,
    items,
    merchants,
    users,
    taxes,
    vendors,
    manufacturers,
    warehouses
  },
  state: {
    initialized: false,
    tick: 0,
    ModalAlert: {},
    loadingIndicator: false,
    indicatorText: '',
    preloader: false,
    preloaderText: '',
    notify: [],
    notifyIntervals: {},
    loadingLogoutIndicator: false,
    sidebarState: 'expanded', // 'collapsed' or 'expanded'
    settings: {},
    appTitle: '',
    appSubTitle: '',
    appVersion: '',
    IdleLockscreenTime: 5, // 5 minutes
    IdleLogoutTime: 15, // 5 minutes
    fetching: {},
    breadcrumb: '',
    countries: {},
    states: []
  },
  mutations: {
    notify: (state, data) => {
      const uuid = uuidv4()
      const duration = data.duration ? data.duration * 1000 : 5000
      const notify = {
        uuid: uuid,
        message: data.message,
        status: data.status,
        duration: duration
      }
      state.notify.push(notify)

      setTimeout(() => {
        if (state.notify.length) {
          const Notified = state.notify.filter((note) => note.uuid !== uuid)
          state.notify = [...Notified]
        }
      }, duration)
    },
    logOut: (state) => {
      const excluded = ['loadingIndicator', 'indicatorText']
      for (const property in state) {
        if (!storeModules.includes(property)) {
          if (property in State) {
            if (!excluded.includes(property)) state[property] = State[property]
          } else {
            delete state[property]
          }
        }
      }
    },
    showIndicator: (state, text) => {
      if (text) state.indicatorText = text
      state.loadingIndicator = true
    },
    hideIndicator: (state) => {
      state.indicatorText = ''
      state.loadingIndicator = false
    },
    showPreloader: (state, text) => {
      if (text) state.preloaderText = text
      state.preloader = true
    },
    hidePreloader: (state) => {
      state.preloaderText = ''
      state.preloader = false
    },
    showAlert: (state, payload) => {
      const uuid = uuidv4()
      if (state.settings._ALLOW_MULTIPLE_ALERTS) {
        state.ModalAlert[uuid] = {
          uuid: uuid,
          title: payload.title ?? '',
          message: payload.message ?? '',
          status: payload.status ?? 'success',
          class: payload.status ?? '',
          showing: true
        }
      } else {
        if (state.ModalAlert.showing) return
        state.ModalAlert = {
          uuid: uuid,
          title: payload.title ?? '',
          message: payload.message ?? '',
          status: payload.status ?? 'success',
          showing: true
        }
      }
    },
    hideAlert: (state, uuid) => {
      if (!state.settings._ALLOW_MULTIPLE_ALERTS) {
        state.ModalAlert.showing = false
      } else {
        state.ModalAlert[uuid].showing = false
      }
    }
  },
  actions: {
    startFetching: ({ state }, item) => {
      state.fetching[item] = true
    },
    stopFetching: ({ state }, item) => {
      delete state.fetching[item]
    },
    StartUp({ dispatch }) {
      dispatch('init')
    },
    async init({ commit, dispatch, state }) {
      if (state.initialized) return
      commit('showIndicator')
      return axios
        .get('/api/settings')
        .then((response) => {
          if (response.data.status === 'success') {
            const config = response.data.result
            state.settings = config
            state.IdleLogoutTime = config?._IDLE_LOGOUT_TIME
            state.IdleLockscreenTime = config?._IDLE_LOCKSCREEN_TIME
            state.appVersion = config?._APP_VERSION
            state.appTitle = config?._APP_TITLE
            state.appSubTitle = config?._APP_SUBTITLE
            state.countries = config?._COUNTRIES
            state.initialized = true
          } else {
            Util.Error(response.data.result)
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            dispatch('auth/logOutUser', true)
          } else {
            Util.Error(error.message)
            commit('hideIndicator')
          }
        })
        .finally(() => {
          commit('hideIndicator')
        })
    },
    states({ commit, dispatch, state }, payload) {
      let state_field = payload.state_field ?? 'states'
      if (!payload.country) {
        state[state_field] = []
      } else {
        commit('showPreloader', 'fetching states...', { root: true })
        axios
          .get('/api/states/' + payload.country)
          .then((response) => {
            if (response.data.status === 'success') {
              state[state_field] = response.data.result
            } else {
              Util.Error(response.data.result)
            }
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch('auth/logOutUser', true)
            } else {
              Util.Error(error.message)
              commit('hideIndicator')
            }
          })
          .finally(() => {
            commit('hidePreloader')
          })
      }
    },
    notify({ commit }, message) {
      commit('notify', message)
    },
    showIndicator({ commit }, text) {
      commit('showIndicator', text)
    },
    hideIndicator({ commit }) {
      commit('hideIndicator')
    },
    showAlert({ commit }, alert) {
      commit('showAlert', alert)
    },
    hideAlert({ commit }, uuid) {
      commit('hideAlert', uuid)
    },
    showPreloader: ({ commit }, text) => {
      commit('showPreloader', text)
    },
    hidePreloader: (commit) => {
      commit('hidePreloader')
    },
    toggleSidebar: ({ state }, sidebarState) => {
      const sidebar = document.querySelector('#AppSideBarMenu')
      const body = document.querySelector('body')
      if (body.classList.contains('has-sidebar')) {
        body.classList.remove('has-sidebar')
        body.classList.add('no-sidebar')
        if (!sidebar.classList.contains('nk-sidebar-inactive'))
          sidebar.classList.add('nk-sidebar-inactive')
      } else {
        body.classList.add('has-sidebar')
        body.classList.remove('no-sidebar')
        if (sidebar.classList.contains('nk-sidebar-inactive'))
          sidebar.classList.remove('nk-sidebar-inactive')
      }
      localStorage.setItem(appName + '-sidebar-state', sidebarState)
      state.sidebarState = sidebarState
    },
    hideSidebar: ({ state }) => {
      const sidebar = document.querySelector('#AppSideBarMenu')
      const body = document.querySelector('body')
      const overlay = document.querySelector('.nk-sidebar-overlay')
      if (sidebar.classList.contains('nk-sidebar-active'))
        sidebar.classList.remove('nk-sidebar-active')
      if (body.classList.contains('nav-shown'))
        body.classList.remove('nav-shown')
      if (overlay) overlay.remove()
      localStorage.setItem(appName + '-sidebar-state', 'collapsed')
      state.sidebarState = 'collapsed'

      const toggleOverlay = document.querySelector('.toggle-overlay')
      if (sidebar.classList.contains('toggle-menu-slide-left')) {
        sidebar.classList.remove('toggle-menu-slide-left')
        if (toggleOverlay) toggleOverlay.remove()
      }
    },
    setBreadcrumb({ state }, title) {
      state.breadcrumb = title
    }
  },
  getters: {
    preloader: (state) => state.preloader,
    notify: (state) => state.notify,
    loadingIndicator: (state) => state.loadingIndicator,
    loadingLogoutIndicator: (state) => state.loadingLogoutIndicator,
    indicatorText: (state) => state.indicatorText,
    breadcrumb: (state) => state.breadcrumb,
    SidebarState: (state) => state.sidebarState,
    settings: (state) => state.settings,
    appTitle: (state) => state.appTitle,
    countries: (state) => state.countries,
    states: (state) => state.states,
    fetching: (state) => {
      const Fetching = {}
      for (let fetching in state.fetching) {
        if (fetching) Fetching[fetching] = true
      }
      return Fetching
    },
    isFetching: (state) => {
      for (let fetching in state.fetching) {
        if (fetching) return true
      }
      return false
    },
    isLoading: (state) =>
      state.loadingIndicator || state.preloader ? true : false,
    initialized: (state) => state.initialized
  }
})
