<template>
  <teleport to="#modalTeleports">
    <div
      class="
        preloader_box
        w3-center
        justify-content-center
        d-flex
        align-items-center
      "
    >
      <div class="loading-spinner d-inline-block mr-2"></div>
      <div class="loading-content w3-center size12 d-inline-block">
        {{ preloaderText !== "" ? preloaderText : "proccessing..." }}
      </div>
    </div>
  </teleport>
</template>
<script>
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    const preloaderText = store.state.preloaderText;
    return { preloaderText };
  },
};
</script>
<style>
@keyframes loading-spinner {
  to {
    transform: rotate(360deg);
  }
}
.preloader_box {
  position: fixed;
  z-index: 111111;
  width: 200px;
  top: 15px;
  left: calc(50% - 80px);
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: rgb(255, 231, 136);
  border-radius: 4px;
}
.loading-spinner {
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 3px solid #f00;
  border-top-color: #333;
  animation: loading-spinner 0.6s linear infinite;
  /* text-indent: 100%; */
  /* white-space: nowrap; */
  overflow: hidden;
}
/*  */
</style>
