import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'

import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import { appName } from '@/helpers/utilities.js'

const requireComponent = require.context(
  './components/BaseComponents',
  false,
  /Base[A-Z]\w+\.(vue|js)$/
)

import { backendURL } from '@/helpers/utilities.js'
import 'tabulator-tables/dist/css/tabulator.min.css'

axios.defaults.baseURL = backendURL
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded'
const token = localStorage.getItem(appName + '-ttk')
if (token) axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

const app = createApp(App)

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName)
  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, '$1'))
  )

  app.component(componentName, componentConfig.default || componentConfig)
})

app.use(store)
;(async () => {
  await store.dispatch('StartUp')
  await store.dispatch('auth/checkLocalStorage')

  app.use(router)
  app.mount('#app')

  store.state.tick = 0
  setInterval(function () {
    if (store.state.tick > 10) {
      store.dispatch('auth/checkIdleTime')
    } else {
      store.state.tick++
    }
  }, 1000)
})()
