import { Util } from '@/helpers/utilities.js'
import { ItemState as State } from '@/store/store-helper.js'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    initialized: false,
    completed: {},
    item: {},
    items: []
  },
  mutations: {
    logOut: (state) => {
      for (const property in state) {
        if (property in State) {
          state[property] = State[property]
        } else {
          delete state[property]
        }
      }
    }
  },
  actions: {
    async getItem({ state, commit, dispatch, rootState }, uuid) {
      if (!uuid) return
      commit('showPreloader', 'fetching item...', { root: true })
      rootState.fetching.getItem = true
      return axios
        .get('/api/item/' + uuid)
        .then((response) => {
          if (response.data.status === 'success') {
            state.item = response.data.result.item
          } else {
            Util.Error(response.data.result)
          }
        })
        .catch((error) => {
          if (error.response?.status === 401)
            commit('auth/logOutUser', { root: true })
          else Util.Error(error.message, 'Error: ' + error.code)
        })
        .finally(() => {
          commit('hidePreloader', null, { root: true })
          dispatch('stopFetching', 'getItem', { root: true })
        })
    },
    updateItem({ commit, state, rootState }, item) {
      commit('showIndicator', 'Saving item...', { root: true })
      state.completed.UpdateItem = false
      if (item.Item.uuid) item.Item._method = 'put'
      if (!item.Item.uuid)
        item.Item.merchant_uuid = rootState.auth.userMerchant.uuid
      let formData = new FormData()
      for (var key in item.Item) {
        if (key === 'item_photo') {
          const photos = item.Item[key]
          if (photos.length) {
            for (let i = 0; i < photos.length; i++) {
              formData.append('item_photo[' + i + ']', photos[i].uuid)
            }
          }
        } else {
          formData.append(key, item.Item[key])
        }
      }
      if (item.ItemPhotos.length) {
        for (var i = 0; i < item.ItemPhotos.length; i++) {
          let file = item.ItemPhotos[i]
          formData.append('item_photo[' + i + ']', file)
        }
      }
      if (item.Item.uuid) {
        return axios
          .post('/api/items/update', formData, {
            headers: {
              'content-type': 'multipart/form-data'
            }
          })
          .then((response) => {
            if (response.data.status === 'success') {
              state.item = response.data.result.item
              state.items = response.data.result.items
              Util.notify({
                message: 'Item updated successfully',
                duration: 5,
                status: 'success'
              })
              state.completed.UpdateItem = true
              return response.data
            } else {
              Util.Error(response.data.result)
              return null
            }
          })
          .catch((error) => {
            if (error.response.status === 401)
              commit('auth/logOutUser', { root: true })
            else Util.Error(error.message, 'Error: ' + error.code)
            return null
          })
          .finally(() => {
            commit('hideIndicator', null, { root: true })
          })
      } else {
        return axios
          .post('/api/items/register', formData, {
            headers: {
              'content-type': 'multipart/form-data'
            }
          })
          .then((response) => {
            if (response.data.status === 'success') {
              state.item = response.data.result.item
              state.items = response.data.result.items
              Util.notify({
                message: 'Item registered successfully',
                duration: 5,
                status: 'success'
              })
              state.completed.UpdateItem = true
              return response.data
            } else {
              Util.Error(response.data.result)
              return null
            }
          })
          .catch((error) => {
            if (error.response?.status === 401)
              commit('auth/logOutUser', { root: true })
            else Util.Error(error.message, 'Error: ' + error.code)
            return null
          })
          .finally(() => {
            commit('hideIndicator', null, { root: true })
          })
      }
    },
    setItem({ state }, item) {
      state.item = item
    },
    setItems({ state }, items) {
      state.items = items
    },
    unsetItem({ state }) {
      state.item = {}
    },
    itemDetail(item) {
      return {
        ...item,
        returnable: item.returnable === 1 ? true : false,
        is_sale: item.is_sale === 1 ? true : false,
        is_purchase: item.is_purchase === 1 ? true : false,
        track_inventory: item.track_inventory === 1 ? true : false
      }
    }
  },
  getters: {
    item: (state) => {
      const item = state.item
      if (Util.isEmpty(item)) return {}
      return {
        ...item
      }
    },
    items: (state) => state?.items ?? [],
    initialized: (state) => state?.initialized,
    completed: (state) => state?.completed
  }
}
