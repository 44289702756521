import { computed, nextTick } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import { appName } from '@/helpers/utilities.js'
import store from '../store'
const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Dashboard'
    }
  },
  {
    name: 'orders',
    path: '/orders',
    component: () => import('../views/Orders.vue'),
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Orders'
    }
  },
  {
    name: 'sales',
    path: '/sales',
    component: () => import('../views/Invoices.vue'),
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Sales'
    }
  },
  {
    name: 'purchases',
    path: '/purchases',
    component: () => import('../views/Bills.vue'),
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Purchases'
    }
  },
  {
    name: 'estimates',
    path: '/estimates',
    component: () => import('../views/Estimates.vue'),
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Estimates'
    }
  },
  {
    name: 'invoices',
    path: '/invoices',
    component: () => import('../views/Invoices.vue'),
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Invoices'
    }
  },
  {
    name: 'bills',
    path: '/bills',
    component: () => import('../views/Bills.vue'),
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Bills'
    }
  },
  {
    name: 'items',
    path: '/items',
    component: () => import('../views/items/Items.vue'),
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Inventory'
    }
  },
  {
    name: 'items-new',
    path: '/items/new',
    component: () => import('../views/items/Items.vue'),
    props: { addNew: true },
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Products'
    }
  },
  {
    name: 'products',
    path: '/items/products',
    component: () => import('../views/items/Items.vue'),
    props: { itemType: 'item' },
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Inventory'
    }
  },
  {
    name: 'products-new',
    path: '/items/products/new',
    component: () => import('../views/items/Items.vue'),
    props: { itemType: 'item', addNew: true },
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Products'
    }
  },
  {
    name: 'services',
    path: '/items/services',
    component: () => import('../views/items/Items.vue'),
    props: { itemType: 'service' },
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Inventory'
    }
  },
  {
    name: 'services-new',
    path: '/items/services/new',
    component: () => import('../views/items/Items.vue'),
    props: { itemType: 'service', addNew: true },
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Inventory'
    }
  },
  {
    name: 'item-detail',
    path: '/item/detail/:uuid?',
    component: () => import('../views/items/ItemDetail.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Item Detail'
    }
  },
  {
    name: 'item-edit',
    path: '/item/edit/:uuid?',
    component: () => import('../views/items/ItemEdit.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Edit Item'
    }
  },
  {
    name: 'products-sale',
    path: '/products/sale',
    component: () => import('../views/items/Items.vue'),
    props: { channel: 'sale', itemType: 'item' },
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Products for Sale'
    }
  },
  {
    name: 'products-sale-new',
    path: '/products/sale/new',
    component: () => import('../views/items/Items.vue'),
    props: { channel: 'sale', itemType: 'item', addNew: true },
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Products for Sale'
    }
  },
  {
    name: 'services-sale',
    path: '/services/sale',
    component: () => import('../views/items/Items.vue'),
    props: { channel: 'sale', itemType: 'service' },
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Services for Sale'
    }
  },
  {
    name: 'services-sale-new',
    path: '/services/sale/new',
    component: () => import('../views/items/Items.vue'),
    props: { channel: 'sale', itemType: 'service', addNew: true },
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Services for Sale'
    }
  },
  {
    name: 'products-purchase',
    path: '/products/purchase',
    component: () => import('../views/items/Items.vue'),
    props: { channel: 'purchase', itemType: 'item' },
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Products for Purchase'
    }
  },
  {
    name: 'products-purchase-new',
    path: '/products/purchase/new',
    component: () => import('../views/items/Items.vue'),
    props: { channel: 'purchase', itemType: 'item', addNew: true },
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Products for Purchase'
    }
  },
  {
    name: 'services-purchase',
    path: '/services/purchase',
    component: () => import('../views/items/Items.vue'),
    props: { channel: 'purchase', itemType: 'service' },
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Services for Purchase'
    }
  },
  {
    name: 'services-purchase-new',
    path: '/services/purchase/new',
    component: () => import('../views/items/Items.vue'),
    props: { channel: 'purchase', itemType: 'service', addNew: true },
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Services for Purchase'
    }
  },
  {
    name: 'purchase-orders',
    path: '/purchase-orders',
    component: () => import('../views/PurchaseOrders.vue'),
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Purchase Orders'
    }
  },
  {
    name: 'customers',
    path: '/customers',
    component: () => import('../views/customers/Customers.vue'),
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Customers'
    }
  },
  {
    name: 'customers-new',
    path: '/customers/new',
    component: () => import('../views/customers/Customers.vue'),
    props: { addNew: true },
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Customers'
    }
  },
  {
    name: 'customer-detail',
    path: '/customers/detail/:uuid?',
    component: () => import('../views/customers/CustomerDetail.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Customer Detail'
    }
  },
  {
    name: 'customer-edit',
    path: '/customers/edit/:uuid?',
    component: () => import('../views/customers/CustomerEdit.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Edit Customer'
    }
  },
  {
    name: 'vendors',
    path: '/vendors',
    component: () => import('../views/vendors/Vendors.vue'),
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Vendors'
    }
  },
  {
    name: 'vendors-new',
    path: '/vendors/new',
    component: () => import('../views/vendors/Vendors.vue'),
    props: { addNew: true },
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Vendors'
    }
  },
  {
    name: 'vendor-detail',
    path: '/vendors/detail/:uuid?',
    component: () => import('../views/vendors/VendorDetail.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Vendor Detail'
    }
  },
  {
    name: 'vendor-edit',
    path: '/vendors/edit/:uuid?',
    component: () => import('../views/vendors/VendorEdit.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Edit Vendor'
    }
  },
  {
    name: 'warehouses',
    path: '/warehouses',
    component: () => import('../views/warehouses/Warehouses.vue'),
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Warehouses'
    }
  },
  {
    name: 'warehouses-new',
    path: '/warehouses/new',
    component: () => import('../views/warehouses/Warehouses.vue'),
    props: { addNew: true },
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Warehouses'
    }
  },
  {
    name: 'warehouse-detail',
    path: '/warehouses/detail/:uuid?',
    component: () => import('../views/warehouses/WarehouseDetail.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Warehouse Detail'
    }
  },
  {
    name: 'warehouse-edit',
    path: '/warehouses/edit/:uuid?',
    component: () => import('../views/warehouses/WarehouseEdit.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Edit Warehouse'
    }
  },
  {
    name: 'manufacturers',
    path: '/manufacturers',
    component: () => import('../views/manufacturers/Manufacturers.vue'),
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Manufacturers'
    }
  },
  {
    name: 'manufacturers-new',
    path: '/manufacturers/new',
    component: () => import('../views/manufacturers/Manufacturers.vue'),
    props: { addNew: true },
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Manufacturers'
    }
  },
  {
    name: 'manufacturer-detail',
    path: '/manufacturers/detail/:uuid?',
    component: () => import('../views/manufacturers/ManufacturerDetail.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Manufacturer Detail'
    }
  },
  {
    name: 'manufacturer-edit',
    path: '/manufacturers/edit/:uuid?',
    component: () => import('../views/manufacturers/ManufacturerEdit.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Edit Manufacturer'
    }
  },
  {
    name: 'transactions',
    path: '/transactions',
    component: () => import('../views/Transactions.vue'),
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Transactions'
    }
  },
  {
    name: 'reports',
    path: '/reports',
    component: () => import('../views/Reports.vue'),
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Reports'
    }
  },
  {
    name: 'settings',
    path: '/settings',
    component: () => import('../views/settings/Settings.vue'),
    meta: {
      requiresAuth: true,
      breadcrumb: '',
      title: 'Settings'
    },
    children: [
      {
        path: '',
        component: () => import('../views/settings/profile.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: '',
          title: 'Profile'
        }
      },
      {
        name: 'settings-profile',
        path: 'profile',
        component: () => import('../views/settings/profile.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: '',
          title: 'Profile'
        }
      },
      {
        name: 'settings-companies',
        path: 'companies',
        component: () => import('../views/settings/companies.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: '',
          title: 'Companies'
        }
      },
      {
        name: 'settings-businesses',
        path: 'businesses',
        component: () => import('../views/settings/businesses.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: '',
          title: 'Businesses'
        }
      },
      {
        name: 'settings-invoice-template',
        path: '/settings/invoice-template',
        component: () => import('../views/settings/invoice-template.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: '',
          title: 'Invoice Templates'
        }
      },
      {
        name: 'settings-taxes',
        path: '/settings/taxes',
        component: () => import('../views/settings/taxes.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: '',
          title: 'Taxes & Charges'
        }
      },
      {
        name: 'settings-users',
        path: 'users',
        component: () => import('../views/settings/users.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: '',
          title: 'Users'
        }
      },
      {
        name: 'settings-security',
        path: 'security',
        component: () => import('../views/settings/security.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: '',
          title: 'Security'
        }
      }
    ]
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('../views/auth/Login.vue'),
    meta: {
      requiresAuth: false,
      breadcrumb: '',
      title: 'Login'
    }
  },
  {
    name: 'register',
    path: '/register',
    component: () => import('../views/auth/Register.vue'),
    meta: {
      requiresAuth: false,
      breadcrumb: '',
      title: 'Register'
    }
  },
  {
    name: '404',
    path: '/404',
    component: () => import('../views/404.vue'),
    meta: {
      requiresAuth: false,
      title: '404 | NOT FOUND'
    }
  },
  {
    path: '/:catchAll(.*)*',
    name: '404',
    component: () => import('../views/404.vue'),
    meta: {
      requiresAuth: false
    }
  }
]

const router = createRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active bold'
})
router.beforeEach(async (to, from, next) => {
  store.dispatch('showIndicator')
  const isLoggedIn = computed(() => store.state.auth.isLoggedIn)
  const isMerchantSet = computed(() => store.state.auth.isMerchantSet)
  const authenticated = computed(() => isLoggedIn.value && isMerchantSet.value)
  const isLocked = computed(() => store.getters['auth/isLockSreeen'])
  const uuid = localStorage.getItem(appName + '-uuid')
  const muuid = localStorage.getItem(appName + '-muuid')

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // console.log(1)
    if (to.name !== 'login' && to.name !== 'register') {
      // console.log(2)
      if (!authenticated.value) {
        // console.log(3)
        //   next({ name: 'login' })
        //   return
        // }
        if (!uuid || !muuid) {
          // console.log(4) //@@@@@@@@@@@@@@
          next({ name: 'login' })
          return
        }
      } else {
        // console.log(5)
        if (isLocked.value) {
          store.dispatch('auth/logOutUser')
          return
        }
        next()
        return
      }
    } else {
      // console.log(7)
      if (authenticated.value) {
        // console.log(8)
        next({ name: 'dashboard' })
        return
      }
      if (!isMerchantSet.value) {
        // console.log(9)
        next({ name: 'login' })
        return
      }
      // console.log(10)
      next()
      return
    }
  } else {
    // console.log(11)
    if (isLocked.value) {
      store.dispatch('auth/logOutUser')
      return
    }
    if (to.name === 'login' || to.name === 'register') {
      // console.log(12)
      if (authenticated.value) {
        // console.log(13)
        next({ name: 'dashboard' })
        return
      }
      if (!isMerchantSet.value) {
        // console.log(14)
        if (isLoggedIn.value && to.name === 'login') {
          // console.log(15)
          next()
          return
        } else if (uuid && to.name === 'register') {
          // console.log(16)
          next({ name: 'login' })
          return
        } else {
          // console.log(17)
          next()
          return
        }
      } else {
        // console.log(18)
        next()
        return
      }
    }
  }
  // console.log(19)
  next()
  return
})
router.afterEach((to) => {
  nextTick(() => {
    store.dispatch('hideIndicator')
    if (to.name !== 'login') localStorage.setItem(appName + '-lp', to.name)
    const appTitle = store.getters.appTitle
    document.breadcrumb = to.meta.breadcrumb || appTitle
    if (to.meta.breadcrumb !== '') {
      store.dispatch('setBreadcrumb', to.meta.breadcrumb)
    } else {
      store.dispatch('setBreadcrumb', '')
    }
    store.dispatch('auth/resetIdleTime')
  })
})

// router.beforeResolve(async to => {
//   store.dispatch('hideIndicator')
//   if (to.name !== 'login') localStorage.setItem(appName + '-lp', to.name)
//   const appTitle = store.getters.appTitle
//   document.breadcrumb = to.meta.breadcrumb || appTitle
//   if (to.meta.breadcrumb !== '') {
//     store.dispatch('setBreadcrumb', to.meta.breadcrumb)
//   } else {
//     store.dispatch('setBreadcrumb', '')
//   }
//   store.dispatch('auth/resetIdleTime')
// })
export default router
