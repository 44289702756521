<template>
  <teleport to="#alertTeleports">
    <template v-if="allow_multiple_alerts">
      <div
        v-for="alert in alerts"
        :key="alert.uuid"
        :class="[alert.showing ? 'w3-block' : '']"
        class="w3-modal"
        style="z-index: 99999 !important"
      >
        <div
          class="
            w3-modal-content w3-animate-top
            drop-shadow
            w3-border w3-center
            pt-2
            alert-box
          "
          style="width: 450px; border-width: 3px"
          :class="[
            alert.status === 'error'
              ? 'w3-border-red'
              : alert.status === 'success'
              ? 'w3-border-green'
              : alert.status === 'warning'
              ? 'w3-border-amber'
              : 'w3-border-blue',
            alert.class ?? '',
            alert.status,
          ]"
        >
          <i
            v-if="alert.status === 'error'"
            class="mt-1 mb-0 mx-3 w3-text-red size32 fas fa-times-circle"
          ></i>
          <i
            v-else-if="alert.status === 'warning'"
            class="
              mt-2
              mb-2
              mx-3
              w3-text-amber
              size32
              fas
              fa-exclamation-circle
            "
          ></i>
          <i
            v-else-if="alert.status === 'success'"
            class="mt-1 mb-0 mx-3 w3-text-green size32 fas fa-check-circle"
          ></i>
          <i
            v-else
            class="mt-1 mb-0 mx-3 w3-text-blue size32 fas fa-info-circle"
          ></i>
          <div class="px-4 pt-2">
            <div v-if="alert.title" class="pt-1 pb-3">
              <h5>{{ alert.title }}</h5>
            </div>
            <div
              v-if="alert.message !== ''"
              class="pt-1 pb-3"
              style="max-height: 500px; overflow: auto"
            >
              {{ alert.message }}
            </div>
          </div>
          <div class="footer w3-border-top w3-right-align">
            <button
              @click="store.dispatch('hideAlert', alert.uuid)"
              class="w3-button w3-white w3-hover-white w3-hoverable hover-bold"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div :class="[alerts.showing ? 'w3-block' : '']" class="w3-modal">
        <div
          class="
            w3-modal-content w3-animate-top
            drop-shadow
            w3-border w3-center
            pt-2
            alert-box
          "
          style="width: 450px; border-width: 3px"
          :class="[
            alerts.status === 'error'
              ? 'w3-border-red'
              : alerts.status === 'success'
              ? 'w3-border-green'
              : alerts.status === 'warning'
              ? 'w3-border-amber'
              : 'w3-border-blue',
            alerts.class ?? '',
            alerts.status,
          ]"
        >
          <i
            v-if="alerts.status === 'error'"
            class="mt-1 mb-0 mx-3 w3-text-red size32 fas fa-times-circle"
          ></i>
          <i
            v-else-if="alerts.status === 'warning'"
            class="
              mt-2
              mb-2
              mx-3
              w3-text-amber
              size32
              fas
              fa-exclamation-circle
            "
          ></i>
          <i
            v-else-if="alerts.status === 'success'"
            class="mt-1 mb-0 mx-3 w3-text-green size32 fas fa-check-circle"
          ></i>
          <i
            v-else
            class="mt-1 mb-0 mx-3 w3-text-blue size32 fas fa-info-circle"
          ></i>
          <!-- <img v-else class="m-3" src="images/check-circle.gif" width="64" /> -->
          <div class="px-4 pt-2">
            <div v-if="alerts.title" class="pt-1 pb-0">
              <h4>{{ alerts.title }}</h4>
            </div>
            <div
              v-if="alerts.message !== ''"
              class="pt-0 pb-3"
              style="max-height: 500px; overflow: auto"
              v-html="alerts.message"
            ></div>
          </div>
          <div class="footer w3-border-top w3-right-align">
            <button
              @click="store.dispatch('hideAlert', alerts.uuid)"
              class="w3-button w3-white w3-hover-white w3-hoverable hover-bold"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </template>
  </teleport>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    const alerts = computed(() => store.state.ModalAlert);
    const allow_multiple_alerts = computed(
      () => store.state.settings._ALLOW_MULTIPLE_ALERTS
    );
    return {
      store,
      alerts,
      allow_multiple_alerts,
    };
  },
};
</script>

<style>
</style>